@import '../../../assets/scss/variables';

.policy-container{
    .p-tabview-panels{
        padding: 0px;
        margin-top: 1rem;
        .ql-editor{
            &::-webkit-scrollbar {
                width: 6px;
                border-radius: 10px;
            }
            &::-webkit-scrollbar-track {
                background: #f3f6ff;
                border-radius: 10px;
            }
            &::-webkit-scrollbar-thumb {
                background: #CBCBCB;
                padding: 2px;
                border-radius: 10px;
            }
        }
    }
    .ql-formats {
        display: none;
    }
    .ql-toolbar.ql-snow .ql-formats{
        display: inline-block;
        vertical-align: middle;
    }
}
.content-tabs{
    margin-top: 1.5rem;
    .p-tabview-nav{
        border:none;
        li{
            margin-right: 0.6rem;
            .p-tabview-nav-link{
                background: #ffffff;
                border: 1px solid #CFD8E3;
                border-radius: 5px;
                padding: 8px 15px;
                margin: 0px;
                .p-tabview-title{
                    font-weight: 400;
                    font-size: 13px;
                }
                &:focus{
                    box-shadow: none;
                    outline: none;
                }
            }
            &.p-tabview-ink-bar{
                display: none;
            }
            &.p-highlight{
                .p-tabview-nav-link{
                    background: #3a476a;
                    border: 1px solid #3a476a;
                    .p-tabview-title{
                        color: $white;
                    }
                    &:focus{
                        box-shadow: none;
                        outline: none;
                    }
                }
            }
        }
    }
}

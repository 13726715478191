@import '../../assets/scss/variables';

.promo-code-section{
    .add-button{
        background: $theme-gray;
        border: none;
        border-radius: 5px;
        height: 40px;
        span {
            font-weight: 500;
            font-size: 14px;
        }
        &:hover {
            background: $black;
        }
    }
    .filter-right {
        .p-inputtext {
            height: 40px;
            font-size: 14px;
            margin: 0px 0px 0px 0px;
            &.p-dropdown-label{
                line-height: 14px;
                font-weight: 600;
                color: $black;
                width: 150px;
                height: 38px;
            }
        }
        .p-dropdown{
            .p-dropdown-trigger-icon{
                font-size: 12px;
            }
        }
        .p-multiselect{
            margin: 0px 0px 0px 0px;
        }
        .error-message {
            left:20px;
        }
        .p-multiselect{
            .p-multiselect-label{
                height: 38px;
                width: 150px;
                font-size: 14px;
                padding: 10px 12px;
            }
            .p-multiselect-trigger{
                .pi{
                    font-size: 12px;
                }
            }
        }
        .p-calendar{
            width: 250px;
        }
        .p-calendar-w-btn-right .p-inputtext{
            border-radius: 6px;
        }
        .p-datepicker-trigger {
            background: #f1f1f1;
            border: 1px solid #ced4da;
            height: 40px;
            position: absolute;
            right: 0px;
            width: 2.2rem;
            .p-icon {
                color: #3a476a;
            }
        }
        .searchBtn {
            background: $theme-gray;
            border: none;
            border-radius: 5px;
            height: 40px;
            span {
                font-weight: 500;
                font-size: 14px;
            }
            &:hover {
                background: $black;
            }
        }
        .exportBtn{
            height: 40px;
        }

        .resetBtn {
            background: $secondary;
            border: none;
            border-radius: 5px;
            height:40px;
            margin-left:0px;

            span {
                font-weight: 500;
                font-size: 14px;
            }

            &:hover {
                background: $theme-gray;
            }
        }
        .search-field{
            margin-right: 0px;
            margin-left: 0px;
            position: relative;
            .pi-search{
                position: absolute;
                top: 20px;
                right: 15px;
            }
            .p-inputtext{
                height: 40px;
            }
        }
        .p-multiselect-panel .p-multiselect-items .p-multiselect-item{
            font-size: 14px;
        }

    }
}
.promocode-table{
    .p-datatable-table{
        .p-datatable-thead{
            display: table;
            width: 100%;
            table-layout: fixed;
        }
        .p-datatable-tbody{
            display: block;
            min-height: 100%;
            height: calc(100vh - 360px);
            overflow: auto;
            tr{
                display: table;
                width: 100%;
                table-layout: fixed;
            }
            &::-webkit-scrollbar {
                width: 4px;
                border-radius: 10px;
            }
            &::-webkit-scrollbar-track {
                background: #f3f6ff;
                border-radius: 10px;
            }
            &::-webkit-scrollbar-thumb {
                background: #CBCBCB;
                padding: 2px;
                border-radius: 10px;
            }
            .action-btn{
                &.edit{
                    background: $white;
                }
                .p-button-icon{
                    font-size: 16px;
                    &.pi-pencil{
                        font-size: 14px;
                    }
                }
            }
        }
    }
}
.add-promo-code-dialog{
    width: 100%;
    max-width: 450px;
    .p-float-label-none{
        margin-top: 20px;
        .p-inputtext{
            width: 100%;
            height: 40px;
            &:focus{
                box-shadow: none;
                border: 1px solid $theme-gray;
            }
            &:hover{
                border: 1px solid $theme-gray;
            }
            &.p-dropdown-label{
                &:focus{
                    box-shadow: none;
                    border:none;
                }
                &:hover{
                    border:none;
                }
            }
        }
       label{
            font-size: 14px;
            left: 0.75rem;
       }
       &.custom-margin{
        margin-top: 25px;
        display: inherit;
       }
       .p-calendar{
            .p-inputtext{
                width: 100%;
                height: 40px;
            }
            .p-datepicker-trigger{
                height: 40px;
                background: $theme-gray;
                border: none;
            }
       }
       .pi-percentage{
        font-size: 13px;
        top: 58%;
       }
    }
    .p-dropdown{
        height: 40px;
        margin-top: 25px;
        line-height: 15px;
        .p-dropdown-label{
            font-size: 14px;
        }
        &:hover{
            border: 1px solid $theme-gray;
        }
    }
    .submitBtn{
        background: $theme-gray;
        border: none;
        border-radius: 5px;
        height: 40px;
        margin-top:25px;
        width: 100%;
        span {
            font-weight: 500;
            font-size: 14px;
        }
        &:hover {
            background: $secondary;
        }
    }
    .p-dialog-header{
        .p-dialog-header-icon{
            background: $secondary;
            .p-dialog-header-close-icon{
                color: $white;
            }
            &:hover{
                background: $theme-gray !important;
            }
            &:focus{
                box-shadow: none;
            }
        }
    }
}

.p-multiselect-items-wrapper {
    max-height: 250px !important;
}
.p-datepicker{
    &.p-datepicker-multiple-month{
        .p-datepicker-group{
            border-right: 1px solid #dee2e6;
            border-left: none;
            &:last-child{
                border-right: 0 none;
            }
        }
    }
    .p-datepicker-buttonbar{
        padding: 4px 0;
        .p-button{
            padding: 0.2rem 1.25rem;
        }
    }
    table td{
        padding: 2px;
        span{
            width: 2rem;
            height: 2rem;
            font-size: 14px;
            &.p-highlight{
                background: $theme-gray;
                color: $white;
            }
        }
        &.p-datepicker-today{
            span{
                background: $secondary;
                color: $white;
                &:hover{
                    background: $theme-gray !important;
                    color: $white !important;
                }
            }
        }
    }
    .p-timepicker{
        padding: 2px;
        span{
            font-size: 16px;
        }
        button{
            height: 1.5rem;
            &:focus{
                box-shadow: none;
            }
        }
    }
    .p-datepicker-header{
        padding: 0.2rem 0.5rem;
    }
}
.p-dialog-mask{
    align-items: baseline;
    padding-top: 1rem;
}
.create-promo-dialog{
    max-width: 675px;
    .p-dialog-header{
        background: $white;
        padding: 2rem 2rem 1rem !important; 
        .p-dialog-title{
            text-decoration: underline;
        }
    }
    .p-dialog-content{
        background: $white;
        .filter-content{
            p{
                span{
                    color: #C7C7C7;
                    font-size: 12px;
                }
            } 
            .p-inputtext{
                &::placeholder{
                    color: #858585;
                    font-size: 12px !important;
                }
            }
            .discount-dropdown{
                height: auto !important;
                width: 60px;
                padding: 0px;
                border: none !important;
                position: absolute;
                top: 7px !important;
                right: 4px;
                .p-dropdown-label{
                    font-size: 15px !important;
                    padding: 6px 12px !important;
                    color: $black !important;
                }
                .p-dropdown-trigger{
                    width: auto;
                    .p-dropdown-trigger-icon{
                        top: 10px;
                    }
                }
                &.p-focus{
                    box-shadow: none;
                }
            }
            .icon-input{
                .pi{
                    position: absolute;
                    right: 12px;
                    font-size: 12px;
                    top: 17px;
                    cursor: pointer;
                    &.pi-percentage{
                        right: 40px;
                        font-size: 11px;
                        top: 19px;
                    }
                    &.relative-tooltip{
                        position: inherit;
                    }
                }
            }
            .p-inputnumber{
                .p-inputtext{
                    width: 100%;
                }
            }
            .unlimited-input{
                width: 110px;
                margin-left: 10px;
            }
            .usage-limit-checkbox{
                width: 18px;
                height: 18px;
                cursor: pointer;
            }
        }
    }
    .error-message{
        position: absolute;
        margin: 2px;
        top: auto;
    }
}

@media (min-width:1200px) and (max-width:1440px){
    .add-promo-code-dialog{
        .p-float-label-none {
            margin-top: 15px;
            &.custom-margin {
                margin-top: 15px;
            }
        }
        .p-dialog-header{
            padding: 1rem 1.5rem 0.5rem;
        }
        .p-dialog-content{
            padding: 0 1.5rem 1.5rem 1.5rem;
        }
    }
    .p-datepicker table td {
        padding: 1px;
    }
}

.promocode-table{
    .p-sortable-column{
        text-align: center !important;
        &.p-highlight{
            display: block !important;
        }
        .p-column-header-content{
            justify-content: center;
        }
    }
    .p-datatable-thead{
        .promocode-column{
            .p-column-header-content{
                justify-content: start !important;
            }
        }
    }
    .p-datatable-tbody > tr > td
    {
        text-align: center;
    }
   .p-column-header-content{
        justify-content: center;
   }
   .p-datatable-tbody{
    .promocode-column{
        text-align: left !important;
        position: relative;
        &::after{
            content: "";
            position: absolute;
            width: 1px;
            height: 100%;
            background: #e1dede;
            right: -10px;
            top: 0px;
        }
        &::before{
            content: "";
            position: absolute;
            width: 1px;
            height: 100%;
            background: #e1dede;
            right: -13px;
            top: 0px;
        }
       }
   }
   
}

.p-dropdown .p-dropdown-clear-icon {
    width: 12px;
}
.voucher-type{
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding-left: 10px;
    span{
        text-align: left;
    }
    .pi{
        color: #858585;
        cursor: pointer;
        position: relative;
        top: 1px;
        &:hover{
            color: $secondary;
        }
    }
}

// Responsive CSS

@media (max-width:960px){
    .promo-code-section{
        .filter-right{
            display: none !important;
            &.mobile-filter-section{
                display: block !important;
                form{
                    display: block !important;
                }
            }
            .search-field{
                width: 100%;
                margin-bottom: 10px;
                .p-inputtext{
                    max-width: 100%;
                    width: 100%;
                }
            }
            .p-calendar, .p-multiselect, .p-dropdown{
                width: 100%;
                margin-bottom: 10px;
            }
            .searchBtn{
                margin-right: 10px;
            }
            .right-section{
                .export-button{
                    background: $black;
                    margin-top: 10px;
                }
            }
        }
    }
    .promocode-table{
        margin: 0px !important;
        .p-datatable-tbody{
            padding: 10px;
            height: calc(100vh - 245px) !important;
            .promocode-column{
                &::before{
                    display: none;
                }
                &::after{
                    content: ":";
                    background: none;
                    top: 30%;
                }
                &.Promocode{
                    &::before{
                        display: block;
                        height: auto;
                        background: transparent !important;
                        right: auto !important;
                        top: auto !important;
                        position: absolute !important;
                        left: 10px;
                        padding-right: 10px;
                    }
                }
            }
            tr{
                background: #F5F5F5;
                border-radius: 5px;
                margin-bottom: 10px;
                td{
                    justify-content: flex-start !important;
                    padding-left: 50% !important;
                    position: relative;
                    text-align: left;
                    border: 1px solid #f1f1f1 !important;
                    white-space: nowrap;
                    .p-column-title{
                        position: absolute;
                        left: 15px;
                    }
                    .status{
                        margin: 0px;
                    }
                    .voucher-type{
                        padding-left: 0px;
                        .pi{
                            margin-left: 10px;
                        }
                    }
                    &:after {
                        content: ':';
                        position: absolute;
                        left: 43%;
                        font-size: 14px;
                        font-weight: 600;
                        top: 30%;
                    }
                }
            }
        }
        .p-paginator{
            .p-link{
                min-width: 2rem;
                height: 2rem;
            }
            .p-paginator-current{
                display: none;
            }
        }
    }
    .discount-vouchers-table{
        .p-datatable-wrapper{
            .p-datatable-thead{
                display: none;
            }
            .p-datatable-tbody{
                tr{
                    background: #F5F5F5;
                    display: grid;
                    td{
                        border: 1px solid #efecec !important;
                        position: relative;
                        padding-left: 46% !important;
                        width: 100% !important;
                        &:before {
                            position: absolute;
                            left: 10px;
                            width: 50%;
                            padding-right: 10px;
                            white-space: nowrap;
                            font-size: 13px;
                            font-weight: 600;
                        }
                        &:after {
                            content: ':';
                            position: absolute;
                            left: 40%;
                            font-size: 14px;
                            font-weight: 600;
                            top: 30%;
                        }
                        &.promocode-column{
                            display: block !important;
                            &:before{
                                content: "Promocode";
                            }
                        }
                        &:nth-of-type(1):before { content: "Promocode"; }
                        &:nth-of-type(2):before { content: "Type of voucher"; }
                        &:nth-of-type(3):before { content: "Bundle ID"; }
                        &:nth-of-type(4):before { content: "Discount"; }
                        &:nth-of-type(5):before { content: "Start Date"; }
                        &:nth-of-type(6):before { content: "End Date"; }
                        &:nth-of-type(7):before { content: "Limit"; }
                        &:nth-of-type(8):before { content: "Usage Count"; }
                        &:nth-of-type(9):before { content: "Status"; }
                        &:nth-of-type(10):before { content: "Affiliate"; }
                        &:nth-of-type(11):before { content: "Actions"; }
                    }
                }
                .promocode-column{
                    text-align: left !important;
                    position: relative;
                    &::before{
                        content: "";
                        position: inherit;
                        width: 1px;
                        height: 100%;
                        background: #e1dede;
                        right: -13px;
                        top: 0px;
                    }
                }
            }
        }
    }
}

@import '../../assets/scss/variables';

.dashboard_section {
    margin-top: 2rem;
    position: relative;
    p{
        strong{
            color: $secondary;
        }
    }
    .icon-bg {
        width: 1.5rem;
        height: 1.5rem;
        cursor: pointer;
        .pi {
            color: #3d9ab9;
        }
    }
    .calendar-logo {
        position: relative;
        top: 0px;
        left: 4px;
        cursor: pointer;
        img{
            height: 28px;
            width: 28px;
            padding: 4px;
            border-radius: 15px;
            &:hover{
                background: #f1f1f1;
            }
        }
    }
    .tabs-block-bg {
        background: #fff;
        .tab-number {
            color: $theme-gray;
            font-weight: bold;
            font-size: 24px;
            .percentage{
                border-radius: 5px;
                padding: 2px 5px;
                margin-left: 0px;
                font-weight: 500;
                min-width: 80px;
                display: block;
                text-align: center;
                font-size: 13px;
                .pi{
                    font-size: 12px;
                    position: relative;
                    top: 1px;
                    margin-right: 2px;
                }
                &.up{
                    border: 1px solid $green-light;
                    color: $green-light;
                }
                &.down{
                    border: 1px solid $secondary;
                    color: $secondary;
                }
                &.normal{
                    border: 1px solid $blue;
                    color: $blue;
                }
            }
            .progressBar{
                background: $blue-light;
                border-radius: 10px;
                margin-top: 4px;
                .progress-percentage{
                    border-radius: 10px;
                }
            }
        }
        span {
            font-size: 15px;
            color: $black;
            display: inline-block;
            vertical-align: middle;
            &.description{
                font-weight: 400;
                margin-left: 4px
            }
        }
    }
    .graph-block-bg {
        @extend .tabs-block-bg;
        padding: 0px 20px !important;
        max-height: 450px;
        overflow-y: auto;
        scrollbar-width: inherit;
        border: none;
        &::-webkit-scrollbar {
            width: 8px;
            border-radius: 5px;
        }
        &::-webkit-scrollbar-track {
            background: $primary;
            border-radius: 5px;
        }
        &::-webkit-scrollbar-thumb {
            background: $grey-dark;
            padding: 2px;
            border-radius: 5px;
        }
        .bundles-progress {
            height: 250px;
            overflow-y: auto;
        }
        .block-header {
            h2 {
                margin: 0px;
                font-size: 22px;
            }
            span {
                font-size: 14px;
                font-weight: 500;
                .pi {
                    font-size: 14px;
                    color: #236c87;
                    position: relative;
                    top: 0px;
                    margin-left: 5px;
                    background: #c1dfea;
                    text-align: center;
                    line-height: 24px;
                    border-radius: 3px;
                }
            }
        }
        .block-main {
            h2{
                margin: 0px;
                font-size: 22px;
                &.secondary-color{
                    color: $secondary;
                }
            }
            .month-span{
                font-weight: 500;
            }
            ul {
                margin: 0px 0px 0px 25px;
                padding: 0px;
                li {
                    list-style: none;
                    font-size: 13px;
                    .dot {
                        width: 10px;
                        height: 10px;
                        border-radius: 25px;
                        background: #1f973a;
                        display: inline-block;
                        margin-right: 5px;
                        &.gray {
                            background: gray;
                        }
                        &.light-green{
                            background: #8de3a0;
                        }
                        &.blue{
                            background: #36a2eb;
                        }
                        &.light-red{
                            background: #ff6384;
                        }
                    }
                }
            }
        }
        .sticky-header{
            position: sticky;
            top: 0rem;
            background: $white;
            z-index: 10;
            padding: 5px 0px;
        }
        .p-chart{
            canvas{
                width: 100% !important;
                height: 100% !important;
            }
        }
    }
    .p-dropdown {
        border: 1px solid #e7ebff;
        height: 40px;
        position: relative;
        text-align: left;
        width: 225px;
    }

    .p-dropdown .p-dropdown-label {
        color: #161b28;
        font-family: Roboto, sans-serif;
        font-size: 14px;
        padding: 12px 15px;
    }

    .dashbord-progressBar {
        .p-progressbar {
            height: 0.5rem;
        }
        .progress-text {
            font-size: 14px;
        }
    }
    .right-section{
        padding: 20px 0px;
        background: #fff;
        box-shadow: 0 0 10px 6px rgba(199, 198, 198, 0.3);
        border-radius: 10px;
        height: 100%;
    }
    .current-previous-btns-wrapper{
        position: absolute;
        right: 0px;
        top: 38px;
        .current-previous-buttons{
            background: #003366;
            border: none;
            border-radius: 5px;
            padding: 0.70rem 1.25rem;
            font-size: 14px;
            font-weight: 600;
            &:hover{
                background: $secondary;
            }
            &:focus{
                box-shadow: none;
            }
        }
        .active{
            .current-previous-buttons{
                background: $secondary;
            }
        }
        .half-width-container{
            display: flex;
            gap: 10px;
        }
    }
}
.country {
    width: 24px;
    font-weight: bold;
}
.doughnut-chart {
    width: 100%;
    height: 100%;
    float: left;
    position: relative;
    .doughnut-center-text {
        position: absolute;
        top: 46%;
        left: -17rem;
        right: 0px;
        line-height: 21px;
        text-align: center;
        font-weight: bold;
        width: 160px;
        margin: 0 auto;
        font-size: 16px;
        .total-count {
            font-weight: normal;
            font-size: 14px;
        }
    }
}
.allRegions-dialog{
    width: 100%;
    max-width: 450px;
    margin: 0 auto !important;
   .p-dialog-header{
    .p-dialog-header-icon{
        &:focus{
            box-shadow:none;
        }
    }
   }
    .p-dialog-content{
        h5{
            margin: 0px 0px 5px;
            color: #8b8b8b;
        }
        .p-dropdown{
            width: 100%;
            height: 45px;
            border: 1px solid #ebebeb;
            .p-dropdown-trigger-icon{
                color: #8b8b8b;
                font-size: 14px;
            }
        }
    }
}
.allfilters-dialog{
    margin: 0 !important;
    width: 100%;
    max-width: 450px;
    .p-dialog-header{
        border-top-right-radius: 0px;
        .p-dialog-header-icon{
            &:focus{
                box-shadow:none;
            }
        }
    }
    .p-dialog-content{
        &:last-of-type {
            border-bottom-right-radius: 0px;
        }
        h5{
            margin: 0px 0px 10px;
            color: #8b8b8b;
        }
        .p-multiselect{
            width: 100%;
            height: 45px;
        }
    }
}
.admin-users-section{
    .heading-sec{
        .pi-cog{
            background: #c1dfea;
            padding: 6px;
            border-radius: 5px;
            margin-right: 2px;
            font-size: 18px;
            cursor: pointer;
            &:hover{
                background: #2f3850;
                color: #c1dfea;
            }
        }
    }
}

.month-year-calendar{
    width: 225px;
    .p-inputtext{
        border-radius: 6px;
        height: 40px;
        font-size: 14px;
    }
    .p-datepicker-trigger {
        background: $white;
        border: 1px solid $white;
        height:38px;
        position: absolute;
        right: 1px;
        top: 1px;
        width: 2.2rem;
        .p-icon {
            color: #3a476a;
        }
        &:focus{
            box-shadow: none;
        }
        &:hover{
            background: none;
            border: none;
        }
    }
}


// Responsive CSS

@media (min-width:1200px) {
    .dashboard_section {
        .col-custom-width {
            width: 23%;
        }
    }
}
@media (min-width:1200px) and (max-width:1400px){
    .dashboard_section .graph-block-bg{
        max-height: 330px;
    }
}
@media (min-width:1401px) and (max-width:1500px){
    .dashboard_section .graph-block-bg{
        max-height: 345px;
    }
}
@media (min-width:1501px) and (max-width:1820px){
    .dashboard_section .graph-block-bg{
        max-height: 410px;
    }
}

@media (max-width: 768px) {
    .dashboard_section{
        .current-previous-btns-wrapper{
            flex-direction: column;
            left: 0px;
            top: -10.6rem;
            .full-width{
                .month-year-calendar{
                    width: 100%;
                    margin-top: 5px;
                }
            }
            .half-width-container{
                justify-content: center;
            }
        }
        .col-custom-width{
            width: 50% !important;
        }
        &.mobile-custon-space{
            padding-top: 6.5rem;
            p{
                text-align: center;
            }
        }
       .graph-block-bg {
            .block-header h2{
                font-size: 18px;
            }
            .block-main h2{
                font-size: 18px;
            }
            span{
                font-size: 13px;
            }
       }
    }
}

@media (max-width: 520px) {
    .tabs-block-mobile{
        flex-direction: column;
    }
    .price-block-mobile{
        justify-content: center !important;
        margin-top: 0.5rem !important;
        .tab-number{
            flex-direction: column;
            gap: 8px;
            .mr-3{
                margin-right: 0rem !important;
            }
        }
    }
}

@media (min-width:769px) and (max-width:960px){
    .admin-header .p-menubar .p-menubar-root-list{
        width: 92%;
        background: none;
        box-shadow: none;
    }
    .dashboard_section{
        .current-previous-btns-wrapper{
            position: inherit;
            top: 20px;
        }
        .graph-block-bg{
            .block-header h2{
                font-size: 18px;
            }
            .block-main h2{
                font-size: 18px;
            }
        }
    }
    .month-year-calendar {
        width: auto;
    }
    .m-grid{
        .md\:col-6{
            width: 100%;
        }
    }
}
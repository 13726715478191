@import '../../assets/scss/variables';

.main{
    background: #F3F6FF;
    min-height: calc(100vh - 0px);
}
.esim-details-section{
    background: #f3f6ff;
    padding: 30px;
    .reportBtn {
        background: $theme-gray;
        border: none;
        border-radius: 5px;
        height: 35px;
        span {
            font-weight: 500;
            font-size: 14px;
        }

        &:hover {
            background: $black;
        }
    }
    .esim-card{
        background: $white;
        padding: 20px;
        border-radius: 5px;
    }
}
.red-color{
    color:red;
}
.gray-color{
    color:gray;
}
.p-menu{
    margin-top: 20px;
    .p-menu-list {
        .p-menuitem{
            .p-menuitem-link{
                .p-menuitem-text{
                    font-weight: 500;
                    font-size: 14px;
                    color: $black;
                }
                .p-menuitem-icon{
                    color: $theme-gray;
                    font-size: 14px;
                }
            }
        }
    }
}
.admin-users-section{
    padding: 20px;
    min-height: calc(100vh - 75px);
    .heading-sec{
        h1{
            margin: 0px;
            font-weight: 600;
            font-size: 24px;
            color: $black;
        }
        .search-field{
            margin-left: 10px;
            .p-inputtext{
                width: 100%;
                max-width: 250px;
                height: 35px;
                background: $white;
                border: 1px solid #ced4da;
                border-radius: 5px;
                font-family: $font-base;
                font-size: 13px;
            }
            .search_icon{
                background: transparent;
                border: none;
                position: absolute;
                right: 0px;
                top: -3px;
                color: $primary;
            }
        }
        &.dashboard-header{
            .p-float-label{
                margin-left: 15px;
                .p-inputwrapper{
                    margin-left: 0px;
                }
                .p-inputwrapper{
                    .p-multiselect-label{
                        width: 185px;
                    }
                }
            }
        }
    }
    .users-data-table{
        margin: 20px 0px;
        background: $white;
        box-shadow: 0px 0px 10px 6px rgba(0, 0, 0, 0.02);
        border-radius: 10px;
        .p-datatable{
            .p-datatable-wrapper{
                border-radius: 10px 10px 0px 0px;
                overflow-x: auto;
                .p-datatable-table{
                    .p-datatable-thead{
                        tr{
                            background: $theme-gray;
                            th{
                                background: $theme-gray;
                                border-width: 0 0 0px 0;
                                padding: 11px 1rem;
                                .p-column-title{
                                    font-weight: 600;
                                    font-size: 14px;
                                    color: $white;
                                    .bundle-refresh{
                                        display: flex;
                                        align-items: center;
                                        justify-content: space-between;
                                        .pi-refresh{
                                            background: $secondary;
                                            padding: 5px;
                                            font-size: 13px;
                                            cursor: pointer;
                                        }
                                    }
                                }
                                .p-sortable-column-icon{
                                    width: 12px;
                                    color: $white;
                                }
                                &.p-highlight{
                                    background: $primary;
                                    border-width: 0 0 0px 0;
                                    border-radius: 5px;
                                    padding: 8px 10px;
                                    margin: 5px;
                                    display: block;
                                    .p-column-title{
                                        color: $theme-gray;
                                    }
                                    .p-sortable-column-icon{
                                        color: $theme-gray;
                                    }
                                }
                                &:focus{
                                    box-shadow: none;
                                }
                            }
                        }
                    }
                    .p-datatable-tbody{
                        tr{
                            td{
                                font-weight: 400;
                                font-size: 14px;
                                border-width: 0 0 1px 0;
                                color: $black;
                                padding: 0.7rem 1rem;
                                word-wrap: break-word;
                                p{
                                    margin: 0px;
                                }
                                .status{
                                    background: #FAFBFF;
                                    border-radius: 5px;
                                    font-weight: 400;
                                    font-size: 12px;
                                    padding: 6px 15px;
                                    width: 86px;
                                    display: inline-block;
                                    text-align: center;
                                    &.completed{
                                        color: #67b173;
                                        background-color: rgba(103,177,115,0.15);
                                    }
                                    &.pending{
                                        color: #efb226;
                                        background-color: rgba(255,200,75,0.15);
                                    }
                                    &.failed{
                                        color: #f17171;
                                        background-color: rgba(241,113,113,0.15);
                                    }

                                    &.used {
                                        color: #888;
                                        background-color: #dcdcdc;
                                    }
                                }
                                .dot{
                                    width: 10px;
                                    height: 10px;
                                    border-radius: 20px;
                                    background: #DEE2FF;
                                    display: inline-block;
                                    margin-right: 6px;
                                    &.active{
                                        background: #29B026;
                                    }
                                    &.inactive{
                                        background: #DB3232;
                                    }
                                }
                                .view-order{
                                    background: $theme-gray;
                                    border: 1px solid rgba(231, 235, 255, 0.59);
                                    border-radius: 5px;
                                    font-weight: 400;
                                    font-size: 10px;
                                    text-transform: uppercase;
                                    color: $white;
                                    padding: 5px 10px;
                                    &:hover{
                                        background: #FAFBFF;
                                        color: $black;
                                    }
                                    .p-button-icon{
                                        font-size: 14px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .p-paginator{
                border-radius: 0px 0px 10px 10px;
                border: none;
                .p-paginator-current{
                    font-weight: 400;
                    font-size: 13px;
                    color: $black;
                }
                .p-paginator-pages{
                    .p-paginator-page{
                        min-width: 25px;
                        height: 25px;
                        font-size: 13px;
                    }
                }
                .p-dropdown{
                    height: auto;
                    border: 1px solid #E7EBFF;
                    .p-dropdown-label{
                        font-size: 12px;
                        padding: 8px 0px 8px 8px;
                    }
                }
                .p-dropdown-trigger{
                    .p-dropdown-trigger-icon{
                        font-size: 12px;
                    }
                }
            }
        }
        .order-details-sec{
            .p-highlight {
                width: auto !important;
            }
            .transaction-heading-p{
                font-weight: 600;
                color: $theme-gray;
            }
            padding: 25px 25px 15px;
            .p-datatable{
                .p-datatable-wrapper{
                    border-radius: 10px;
                    .p-datatable-thead{
                        tr{
                            th{
                                border:1px solid #D5D5D5;
                            }
                        }
                    }
                    .p-datatable-tbody{
                        tr{
                            td{
                                border:1px solid #D5D5D5;
                                .promo-code-span{
                                    display: block;
                                    font-size: 13px;
                                    margin-top: 5px;
                                }
                            }
                        }
                    }
                    .p-datatable-tfoot{
                        border: 1px solid #D5D5D5;
                        tr{
                            td{
                                border:none;
                                padding: 16px 30px;
                                font-weight: 600;
                                color: $primary;
                                font-size: 15px;
                            }
                        }
                    }
                }
            }
            .list-box{
                border-radius: 10px;
                .p-datatable{
                    .p-datatable-wrapper{
                        .p-datatable-table{
                            .p-datatable-thead{
                                tr{
                                    th{
                                        padding: 14px 25px;
                                    }
                                }
                            }
                            .p-datatable-tbody{
                                tr{
                                    td{
                                        padding: 5px 25px;
                                    }
                                }
                            }
                        }
                    }
                }
                .notes-header{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    .p-button-icon-only{
                        background: $secondary;
                        padding: 5px 5px;
                        font-size: 12px;
                        cursor: pointer;
                        width: auto;
                        border-radius: 2px;
                        border: none;
                        position: relative;
                        top: -1px;
                        .p-button-icon{
                            font-size: 13px;
                        }
                    }
                }
                .left{
                    .iccid-tag{
                        text-decoration: underline;
                        
                    }
                    .iccid-tag:hover{
                        color: $secondary;
                        cursor: pointer;
                        
                    }
                }
                .right{
                    .status{
                        display: inline;
                        padding: 5px 10px;
                        border-radius: 3px;
                        font-size: 12px;
                    }
                    p{
                        font-size: 16px;
                        margin: 0px;
                    }
                    .icon-button{
                        background: none;
                        border: none;
                        padding: 0px;
                        width: auto;
                        margin: 0px 0px 0px 10px;
                        .p-button-icon{
                            color: $secondary;
                            font-size: 20px; 
                        }
                    }
                }
                .orderid-icon{
                    width: auto;
                    padding: 0px;
                    position: relative;
                    left: 10px;
                    top: 1px;
                }
                h2{
                    margin: 0px;
                    font-size: 16px;
                    color: $primary;
                    background: #f3f6ff;
                    padding: 10px 15px;
                    border-radius: 2px;
                }
                ul{
                    padding: 0px;
                    li{
                        list-style-type: none;
                        font-size: 14px;
                        margin-bottom: 15px;
                        color: $black;
                        word-wrap: break-word;
                        span{
                            font-weight: 500;
                            color:#999999;
                            &.user-data-span{
                                color: $black;
                                word-break: break-all;
                                word-wrap: break-word;
                            }
                        }
                        .EsimCompatibleDetails {
                            display:block;
                            font-style:italic;
                            color:$secondary;
                            padding:5px 0px;

                            .esimCompatible {
                                color:$green;
                            }

                            .esimNonCompatible {
                                color:$secondary;
                            }
                        }
                        &::marker{
                            color: $black;
                        }

                        .deviceDetails {
                            padding-left:10px;
                            color:#000;
                            cursor:pointer;
                            top: 2px;
                            position: relative;
                        }
                    }
                }
                .p-panel{
                    .p-panel-header{
                        background: $theme-gray;
                        border: 1px solid $theme-gray;
                        padding: 11px 1rem;
                        color: $white;
                        height: 48px;
                        .p-panel-title{
                            width: 100%;
                        }
                    }
                    .p-panel-content{
                        min-height: 350px;
                        max-height: 350px;
                        border: 1px solid #e7e7e7;
                        overflow: auto;
                        &::-webkit-scrollbar {
                            width: 4px;
                            border-radius: 10px;
                        }
                        &::-webkit-scrollbar-track {
                            background: $white;
                            border-radius: 10px;
                        }
                        &::-webkit-scrollbar-thumb {
                            background: $white;
                            padding: 2px;
                            border-radius: 10px;
                        }
                        .add-notes{
                            .p-inputtext{
                                width: 100%;
                                border-radius: 0px;
                                border: none;
                                font-size: 14px;
                                color: $black;
                                padding: 0rem 0.4rem;
                                &::placeholder{
                                    font-weight: 500;
                                }
                                &:focus{
                                    box-shadow: none;
                                }
                            }
                            .p-inputtextarea{
                                resize: none;
                                padding: 0.75rem 0.75rem;
                                border-radius: 12px 12px 0px 12px; 
                                border: 1px solid #ECECEC;
                                font-size: 13px;
                                color: $black;
                                &::placeholder{
                                    font-weight: 400;
                                    color: #ADADAD;
                                }
                            }
                            .p-button{
                                padding: 5px 10px;
                                margin: 5px 0px 0px 5px;
                                border-radius: 4px;
                                border: none;
                                .p-button-label{
                                    font-weight: 400;
                                    font-size: 12px;
                                }
                                &.save{
                                    background: #026437;
                                }
                            }
                        }
                        .notes-block{
                            height: 300px;
                            overflow: auto;
                            padding-right: 1rem;
                            margin-top: 0rem;
                            &::-webkit-scrollbar {
                                width: 4px;
                                border-radius: 10px;
                            }
                            &::-webkit-scrollbar-track {
                                background: #f3f6ff;
                                border-radius: 10px;
                            }
                            &::-webkit-scrollbar-thumb {
                                background: #CBCBCB;
                                padding: 2px;
                                border-radius: 10px;
                            }
                            .notes-list{
                                margin-bottom: 15px;
                                border: 1px solid #ECECEC;
                                padding: 10px;
                                border-radius: 5px;
                                .title{
                                    font-size: 14px;
                                    font-weight: 500;
                                    line-height: 140%;
                                    margin-bottom: 5px;
                                }
                                .description{
                                    background:#F9F9F9;
                                    padding: 10px 12px;
                                    color: $black;
                                    font-size: 14px;
                                    font-weight: 500;
                                    line-height: 160%;
                                    border-radius: 4px;
                                }
                                .time{
                                    text-align: right;
                                    font-size: 11px;
                                    font-weight: 400;
                                    line-height: 140%;
                                    margin-top: 5px;
                                }
                            }
                        }
                    }
                }
            }
            .steps-sec{
                background: #fafbff;
                border-radius: 5px;
                padding: 15px;
                margin-bottom: 20px;
                border: 1px solid #f4f6ff;
                .p-steps{
                    .p-steps-item{
                        .p-menuitem-link{
                            background: transparent;
                            font-size: 14px;
                            .p-steps-number{
                                font-size: 14px;
                            }
                            .p-steps-title{
                                color: $primary;
                            }
                        }
                        &.p-highlight{
                            .p-menuitem-link{
                                .p-steps-number{
                                    color: $white;
                                    background: #67b173;
                                    border: none;
                                }
                                .p-steps-title{
                                    color: #67b173;
                                }
                            }
                        }
                    }
                }
            }
            .statusFields{
                padding: 5px 10px;
                border-radius: 3px;
                font-weight: 400 !important;
                font-size: 12px;
                text-transform: uppercase;
                margin-left: 5px;
            }
            .PENDING{
                color: #efb226 !important;
                background-color: rgba(255, 200, 75, 0.15);
            }
            .COMPLETED{
                color: #67b173 !important;
                background-color: rgba(103, 177, 115, 0.15);
            }
            .FAILED{
                color: #f17171 !important;
                background-color: rgba(241,113,113,0.15);
            }
            .Released {
                background: #B3CEC2 !important;
                color: #026437 !important;
            }
            .Installed {
                background: #1D774E !important;
                color: #ffffff !important;
            }
            .ACTIVE {
                color: #67b173 !important;
                background-color: rgba(103, 177, 115, 0.15);
            }
            .CANCELLING{
                color: #efb226 !important;
                background-color: rgba(255, 200, 75, 0.15);
            }
            .INACTIVE{
                color: #f17171 !important;
                background-color: rgba(241,113,113,0.15);
            }
            .Enabled {
                background: #D3D9FF !important;
                color: #33409C !important;
            }
            .Downloaded {
                background: #33409C !important;
                color: #ffffff !important;
            }
            .Disabled {
                background: #D9D9D9 !important;
                color: #797979 !important;
            }
            .Unavailable {
                background: #FBE0DA !important;
                color: #E12D06 !important;
            }
        }
        &.promocode-table{
            .p-datatable-thead{
                .promocode-column{
                    &.iccid-column{
                        width: 16%;
                        &.p-highlight{
                            display: table-cell !important;
                            width: 262px;
                            border-radius: 0px !important;
                            padding: 5px !important;
                            background: transparent !important;
                            .p-column-header-content{
                                background: #c1deea;
                                padding: 12px 10px;
                                border-radius: 5px;
                            }
                        }
                    }
                }
            }
            .p-datatable-tbody .promocode-column{
                &.iccid-column{
                    width: 16%;
                }
            }
        }
    }
    .heading-sec{
        .p-inputwrapper{
            margin-left: 0px;
            .p-multiselect-label{
                height: 40px;
                font-size: 15px;
            }
        }
    }
    .exportBtn {
        background: $grey-dark;
        border: none;
        border-radius: 5px;
        height: 35px;

        span {
            font-weight: 500;
            font-size: 14px;
        }

        &:hover {
            background: $secondary;
        }
    }
}
.breadcrumb-section{
    margin-left: 15px;
    .p-breadcrumb{
        padding: 5px 10px;
        .p-breadcrumb-list{
            li{
                .p-menuitem-link{
                    font-size: 13px;
                    .p-menuitem-icon{
                        font-size: 13px;
                    }
                }
                &.pi{
                    font-size: 10px;
                }
                .p-icon{
                    width: 11px;
                    height: 11px;
                }
            }
        }
    }
}
.p-dropdown .p-dropdown-trigger{
    .p-icon{
        width: 12px;
    }
}
.admin-userlist-section {
    padding: 20px;
    min-height: calc(100vh - 75px);

    .heading-sec {

        h2 {
            margin: 0px;
            font-weight: 600;
            font-size: 24px;
            color: $black;
        }

        .searchBtn {
            background: $theme-gray;
            border: none;
            border-radius: 5px;
            height: 35px;
            span {
                font-weight: 500;
                font-size: 14px;
            }

            &:hover {
                background: $black;
            }
        }

        .resetBtn {
            background: $secondary;
            border: none;
            border-radius: 5px;
            height: 35px;
            margin-left:10px;

            span {
                font-weight: 500;
                font-size: 14px;
            }

            &:hover {
                background: $theme-gray;
            }
        }

        .exportBtn {
            border: none;
            background: #3a476a;
            padding: 10px 20px;
            border-radius: 5px;
            .p-button-label,.p-button-icon{
                font-size: 15px;
            }
            &:hover{
                background: $secondary;
            }
        }

        .filter-right {
            display: flex;
            align-items: center;
            gap: 15px;
            .p-inputtext {
                height: 35px;
                font-size: 14px;
                margin: 0px;
            }
            .error-message {
                left:20px;
            }
            .p-calendar-w-btn-right{
                width: 200px;
                .p-inputtext{
                    border-radius: 6px;
                }
                &.customers-daterange{
                    width: 13.5rem;
                    .p-datepicker-trigger{
                        right: 0px;
                    }
                }
            }
            .p-datepicker-trigger {
                background: #f1f1f1;
                border: 1px solid #ced4da;
                height: 35px;
                position: absolute;
                right: 10px;
                width: 2.2rem;
                .p-icon{
                    color: #3a476a;
                }
            }
            .users-search-input{
                position: relative;
                margin-right: 10px;
                .error-message{
                    left: 10px;
                    position: absolute;
                    top: 40px;
                }
                .p-dropdown{
                    top: 0px;
                    border: 1px solid #ced4da;
                }
                &.email-input{
                    .p-inputtext{
                        min-width: 330px;
                    }
                }
            }
        }
    }

    .users-data-table {
        margin: 20px 0px 0px;
        background: $white;
        box-shadow: 0px 0px 10px 6px rgba(0, 0, 0, 0.02);
        border-radius: 10px;

        .custom-table {
            border: 1px solid #E7EBFF;
            margin-top: 15px;
            border-bottom: none;
            border-radius: 10px;
            .custom-table-header {
                background: $grey-dark;
                border-radius: 10px 10px 0px 0px;

                .table-grid {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;

                    .col-ul {
                        justify-content: flex-start;
                        flex-grow: 1;
                        flex-basis: 0;
                        padding: 0px 20px;
                        align-items: center;
                        display: flex;
                        gap:18px;
                        li {
                            list-style: none;
                            display: inline-block;
                            width: 15%;
                            color: #fff;
                            font-size: 14px;
                            font-weight: 600;

                            &.userName{
                                width:16%;
                            }

                            &.userEmail{
                                width:22%;
                            }

                            &.userPhone {
                                width:20%;
                            }

                            &.affiliateUser {
                                width:20%;
                                text-align: center;
                            }
                            span {
                                cursor: pointer;
                                padding: 12px 10px;
                                margin-left: -10px;
                                border-radius: 5px;

                                i {
                                    font-size: 13px;
                                    margin-left: 4px;
                                    cursor: pointer;
                                }

                                &:hover, &.selectedList {
                                    background:$primary;
                                    color: $grey-dark;
                                }
                            }
                        }
                    }
                }
            }
            .p-dataview {
                min-height: 50px;
                position: relative;
                .p-dataview-content {
                    background: #ffffff;
                    color: #495057;
                    border: 0 none;
                    padding: 0;

                    .grid {
                        display: flex;
                        flex-wrap: wrap;
                        margin-right: 0;
                        margin-left: 0;
                        margin-top: 0;
                        .custom-bg{
                            border-bottom: 1px solid #F0F0F0;
                            // &:nth-child(even){
                            //     background: #f5f5f5;
                            // }
                            &:hover{
                                background: #f9f9f9;
                            }
                        }
                        .custom-table-body {
                            .table-grid {
                                display: flex;
                                flex-wrap: wrap;
                                align-items: center;
                                .col-ul {
                                    justify-content: flex-start;
                                    flex-grow: 1;
                                    flex-basis: 0;
                                    margin:0px;
                                    padding: 10px 15px;
                                    align-items: center;
                                    line-height: 124%;
                                    gap: 18px;
                                    li {
                                        list-style: none;
                                        display: inline-block;
                                        width: 15%;
                                        color: #161b28;
                                        font-size: 14px;
                                        font-weight: 400;
                                        vertical-align: middle;

                                        &.userName{
                                            width:16%;
                                            word-break: break-word;
                                        }

                                        &.userEmail{
                                            width:22%;
                                            word-wrap: break-word;
                                        }

                                        &.userPhone {
                                            width:20%
                                        }

                                        &.affiliateUser {
                                            width:20%;
                                            text-align: center;
                                            .assign-button{
                                                background: none;
                                                border: none;
                                                padding: 0px;
                                                color: #1F3A9C;
                                                text-decoration: underline;
                                                font-size: 14px;
                                                &:hover{
                                                    color: #FF0053;
                                                }
                                            }
                                            .affiliate-edit{
                                                border-radius: 4px;
                                                background: #F2F4F6;
                                                padding: 8px;
                                                display: flex;
                                                align-items: center;
                                                justify-content: space-between;
                                                width: 100%;
                                                max-width: 200px;
                                                margin: 0 auto;
                                                border: 1px solid #eaecef;
                                                .affiliate-name{
                                                    width: 70%;
                                                    text-align: left;
                                                    word-wrap: break-word;
                                                }
                                                .p-button{
                                                    border-radius: 10px;
                                                    background: #D6D6D6;
                                                    width: 18px;
                                                    height: 18px;
                                                    padding: 5px;
                                                    border: none;
                                                    margin-left: 5px;
                                                    display: flex;
                                                    align-items: center;
                                                    justify-content: center;
                                                    .pi{
                                                        font-size: 10px;
                                                        color: #727272;
                                                    }
                                                    &:hover{
                                                        background: #3E3F6C;
                                                        .pi{
                                                            color: $white;
                                                        }
                                                    }
                                                    &.close{
                                                        &:hover{
                                                            background: $secondary;
                                                        }
                                                    }
                                                }
                                            }
                                            .p-dropdown{
                                                border: none;
                                                border-radius: 4px;
                                                background: #F4F4F4;
                                                width: 100%;
                                                max-width: 200px;
                                                text-align: left;
                                                border: 1px solid #eaecef;
                                                .p-dropdown-label{
                                                    font-size: 14px;
                                                    padding: 9px 10px;
                                                }
                                                .p-dropdown-trigger{
                                                    width: 2rem;
                                                }
                                            }
                                        }
                                        &.actions-tab{
                                            width: 16%;
                                            display: flex;
                                        }

                                        .view-order{
                                            background: $white;
                                            border: 1px solid #dbedf3;
                                            border-radius: 5px;
                                            font-weight: 400;
                                            font-size: 11px;
                                            color: $grey-dark;
                                            padding: 8px 10px;

                                            &:hover{
                                                background: $theme-gray;
                                                color: $white;
                                            }

                                            .p-button-icon{
                                                font-size: 14px;
                                            }
                                        }

                                    }
                                    &.sorting-li{
                                        display: flex;
                                    }
                                }
                            }
                        }

                        .p-dataview-emptymessage {
                            text-align: center;
                            padding: 20px;
                        }
                    }
                }
            }
        }
    }
}

.admin-affiliateslist-section {
    padding: 20px;
    min-height: calc(100vh - 75px);
    .heading-sec {
        h2 {
            margin: 0px;
            font-weight: 600;
            font-size: 24px;
            color: $black;
        }
        .searchBtn {
            background: $theme-gray;
            border: none;
            border-radius: 5px;
            height:35px;
            span {
                font-weight: 500;
                font-size: 14px;
            }

            &:hover {
                background: $black;
            }
        }
        .resetBtn {
            background: $secondary;
            border: none;
            border-radius: 5px;
            height: 35px;
            margin-left:10px;
            span {
                font-weight: 500;
                font-size: 14px;
            }

            &:hover {
                background: $theme-gray;
            }
        }

        .filter-right {
            display: flex;
            align-items: center;
            gap: 15px;

            .p-inputtext {
                height: 35px;
                font-size: 14px;
                margin: 0px;
            }

            .error-message {
                left:20px;
            }
            .p-calendar-w-btn-right{
                width: 200px;
                .p-inputtext{
                    border-radius: 6px;
                }
                &.customers-daterange{
                    width: 13.5rem;
                    .p-datepicker-trigger{
                        right: 0px;
                    }
                }
            }
            .p-datepicker-trigger {
                background: #f1f1f1;
                border: 1px solid #ced4da;
                height: 35px;
                position: absolute;
                right: 10px;
                width: 2.2rem;
                .p-icon {
                    color: #3a476a;
                }
            }
            .users-search-input{
                position: relative;
                margin-right: 10px;
                .error-message{
                    left: 10px;
                    position: absolute;
                    top: 45px;
                }
                .p-multiselect{
                    .p-multiselect-label{
                        height: 35px;
                        width: 125px;
                        font-size: 14px;
                        padding: 9px 12px;
                    }
                    .p-multiselect-trigger{
                        .pi{
                            font-size: 12px;
                        }
                    }
                }
            }
        }
        .export-button{
            border: none;
            background: #3a476a;
            padding: 12px 20px;
            border-radius: 5px;
            .p-button-label,.p-button-icon{
                font-size: 15px;
            }
            &:hover{
                background: $secondary;
            }
        }
    }

    .users-data-table {
        margin: 20px 0px 0px;
        background: $white;
        box-shadow: 0px 0px 10px 6px rgba(0, 0, 0, 0.02);
        border-radius: 10px;
        .custom-table {
            border: 1px solid #E7EBFF;
            margin-top: 15px;
            border-bottom: none;
            border-radius: 10px;
            .custom-table-header {
                background: $grey-dark;
                border-radius: 10px 10px 0px 0px;
                .table-grid {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;

                    .col-ul {
                        justify-content: flex-end;
                        flex-grow: 1;
                        flex-basis: 0;
                        align-items: center;
                        display: flex;
                        li {
                            list-style: none;
                            display: inline-block;
                            width: 20%;
                            color: #fff;
                            font-size: 14px;
                            font-weight: 600;

                            &.affiliateUserEmail {
                                width:33%;
                            }

                            &.affiliateUserPhone {
                                width:18%;
                            }
                            &.affiliateId{
                                width:25%;
                                &.discount{
                                    text-align: center;
                                }
                            }
                            &.affiliateUserStatus {
                                width:10%;
                            }
                            &.actionsColumn{
                                width:7%;
                            }
                            span {
                                cursor: pointer;
                                padding: 12px 10px;
                                margin-left: -10px;
                                border-radius: 5px;

                                i {
                                    font-size: 13px;
                                    margin-left: 4px;
                                    cursor: pointer;
                                }

                                &:hover, &.selectedList {
                                    background: $primary;
                                    color: $theme-gray;
                                }
                            }
                        }
                    }
                }
            }

            .p-dataview {
                min-height: 50px;
                position: relative;

                .p-dataview-content {
                    background: #ffffff;
                    color: #495057;
                    border: 0 none;
                    padding: 0;

                    .grid {
                        display: flex;
                        flex-wrap: wrap;
                        margin-right: 0;
                        margin-left: 0;
                        margin-top: 0;
                        .custom-bg{
                            border-width: 0px;
                            &:nth-child(even){
                                background: #f5f5f5;
                            }
                        }
                        .custom-table-body {
                            .table-grid {
                                display: flex;
                                flex-wrap: wrap;
                                border-bottom: 1px solid #E7EBFF;
                                align-items: center;

                                .col-ul {
                                    justify-content: flex-end;
                                    flex-grow: 1;
                                    flex-basis: 0;
                                    display: flex;
                                    align-items: center;
                                    li {
                                        list-style: none;
                                        display: inline-block;
                                        width: 20%;
                                        color: #161b28;
                                        font-size: 14px;
                                        font-weight: 400;
                                        vertical-align: middle;
                                        line-height: 22px;

                                        &.affiliateUserEmail {
                                            width:33%;
                                        }
                                        &.affiliateUserPhone {
                                            width:18%;
                                        }
                                        &.affiliateId{
                                            width:25%;
                                            font-size: 14px;
                                            line-height: 120%;
                                            white-space: nowrap;
                                            overflow: hidden;
                                            text-overflow: ellipsis;
                                            &.discount{
                                                text-align: center;
                                            }
                                        }
                                        &.actionsColumn{
                                            width:7%;
                                            padding-left: 20px;
                                        }
                                        &.affiliateUserStatus {
                                            width:10%;
                                            .status{
                                                margin: 0;
                                                text-align: center;
                                            }
                                            .p-inputswitch{
                                                width: 36px;
                                                height: 20px;
                                                .p-inputswitch-slider{
                                                    &::before{
                                                        width: 16px;
                                                        height: 16px;
                                                        left: 1px;
                                                        margin-top: -8px;
                                                    }
                                                }
                                                &.p-highlight{
                                                    .p-inputswitch-slider{
                                                        background: #00AC65;
                                                        &::before{
                                                            transform: translateX(15px);
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        .pi{
                                            cursor: pointer;
                                            &:hover{
                                                color: $theme-gray !important;
                                            }
                                        }

                                        .view-order{
                                            background: $theme-gray;
                                            border: 1px solid rgba(231, 235, 255, 0.59);
                                            border-radius: 5px;
                                            font-weight: 400;
                                            font-size: 10px;
                                            text-transform: uppercase;
                                            color: $white;
                                            padding: 5px 10px;
                                            bottom:10px;

                                            &:hover{
                                                background: #FAFBFF;
                                                color: $black;
                                            }

                                            .p-button-icon{
                                                font-size: 14px;
                                            }
                                        }

                                    }
                                }
                            }
                        }

                        .p-dataview-emptymessage {
                            text-align: center;
                            padding: 20px;
                        }
                    }
                }
            }
        }
    }
}

.admin-orderlist-section {
    padding: 20px;
    min-height: calc(100vh - 75px);
    .heading-sec {
        h2 {
            margin: 0px;
            font-weight: 600;
            font-size: 24px;
            color: $black;
        }
        .exportBtn {
            background: $grey-dark;
            border: none;
            border-radius: 5px;
            padding: 0.75rem 1rem;
            span {
                font-weight: 500;
                font-size: 14px;
            }
            &:hover {
                background: $secondary;
                .count{
                    background: $grey-dark !important;
                }
            }
            &.filter-btn{
                padding: 0.6rem 1.2rem 0.6rem 1rem;
                .count{
                    width: 20px;
                    height: 20px;
                    border-radius: 20px;
                    background: $secondary;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 12px;
                    margin-left: 5px;
                }
                .p-button-label{
                    display: flex;
                }
            }
        }
    }

    .filter-right {
        display: flex;
        align-items: center;
        gap: 15px;
        justify-content: space-between;
        margin-top: 15px;
        .email-orderid-iccid{
            width: 100%;
            .users-search-input{
                width: 100%;
                max-width: 475px;
                margin-right: 5px;
                .p-inputtext{
                    width: 100%;
                }
            }
        }
        .p-inputtext {
            height:35px;
            font-size: 14px;
            margin: 0px 8px 0px 0px;
            width: 160px;
            &.email-orderid-iccid{
                width: 100%;
            }
        }
        .p-multiselect{
            margin: 0px 8px 0px 0px;
        }

        .error-message {
            left:20px;
        }
        .p-multiselect{
            .p-multiselect-label{
                height: 35px;
                width: 130px;
                font-size: 14px;
                padding: 9px 10px;
            }
            .p-multiselect-trigger{
                width: 2rem;
                .pi{
                    font-size: 12px;
                }
            }
        }
        .provider-select{
            .p-multiselect{
                .p-multiselect-label{
                    width: 90px;
                }
            }
        }
        .p-calendar-w-btn-right{
            width: 230px;
            .p-inputtext{
                border-radius: 6px;
            }
        }
        .p-datepicker-trigger {
            background: #f1f1f1;
            border: 1px solid #ced4da;
            height: 35px;
            position: absolute;
            right: 8px;
            width: 2.2rem;
            .pi-calendar {
                color: #3a476a;
            }
        }
        .users-search-input{
            .p-dropdown{
                top: 0px;
                margin-right: 8px;
                border: 1px solid #ced4da;
            }
            &.Promocode-select{
                .p-dropdown{
                    width: 160px;
                }  
            }
        }
        .affiliate-dropdown{
            .p-dropdown{
                width: 185px;
            }
        }

        .searchBtn {
            background: $theme-gray;
            border: none;
            border-radius: 5px;
            height: 35px;

            span {
                font-weight: 500;
                font-size: 14px;
            }

            &:hover {
                background: $black;
            }
        }

        .resetBtn {
            background: $secondary;
            border: none;
            border-radius: 5px;
            height: 35px;
            margin-left:8px;

            span {
                font-weight: 500;
                font-size: 14px;
            }

            &:hover {
                background: $theme-gray;
            }
            &.hide-show-btn{
                padding: 0.75rem 0.5rem;
            }
        }
        .search-field{
            margin-left: 15px;
        }

    }

    .users-data-table {
        margin: 20px 0px 0px;
        background: $white;
        box-shadow: 0px 0px 10px 6px rgba(0, 0, 0, 0.02);
        border-radius: 10px;

        .custom-table {
            border: 1px solid #E7EBFF;
            margin-top: 15px;
            border-bottom: none;
            border-radius: 10px;
            .custom-table-header {
                background: $grey-dark;
                border-radius: 10px 10px 0px 0px;

                .table-grid {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;

                    .col-ul {
                        flex-grow: 1;
                        flex-basis: 0;
                        padding: 0px 20px;
                        align-items: center;
                        display: flex;
                        justify-content: space-around;
                        text-align: center;
                        li {
                            list-style: none;
                            display: inline-block;
                            width: 10%;
                            color: #fff;
                            font-size: 12px;
                            font-weight: 600;

                            &.customer{
                                width:12%;
                                text-align: left;
                                span{
                                    margin-left: -10px;
                                }
                            }
                            &.bundleName{
                                width:12%
                            }
                            &.price {
                                width:8%;
                            }
                            &.actions{
                                width: 6%;
                                .pi{
                                    display: none;
                                }
                            }
                            span {
                                cursor: pointer;
                                padding: 12px 10px;
                                border-radius: 5px;
                                i {
                                    font-size: 10px;
                                    margin-left: 4px;
                                    cursor: pointer;
                                }

                                &:hover, &.selectedList {
                                    background: $primary;
                                    color: $theme-gray;
                                }

                            }
                            &.actions-column{
                                width: 4%;
                                span{
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }

            .p-dataview {
                min-height: 50px;
                position: relative;

                .p-dataview-content {
                    background: #ffffff;
                    color: #495057;
                    border: 0 none;
                    padding: 0;
                    min-height: 510px;
                    height: calc(100vh - 325px);
                    overflow-x: auto;
                    &::-webkit-scrollbar {
                        width: 4px;
                        border-radius: 10px;
                    }
                    &::-webkit-scrollbar-track {
                        background: #f3f6ff;
                        border-radius: 10px;
                    }
                    &::-webkit-scrollbar-thumb {
                        background: #CBCBCB;
                        padding: 2px;
                        border-radius: 10px;
                    }
                    .grid {
                        display: flex;
                        flex-wrap: wrap;
                        margin-right: 0;
                        margin-left: 0;
                        margin-top: 0;
                        .custom-bg{
                            border-bottom: 1px solid #f5f5f5;
                            &:nth-child(even){
                                background: $white;
                            }
                            &:hover{
                                background: #f5f5f5;
                            }
                        }
                        .custom-table-body {
                            .table-grid {
                                display: flex;
                                flex-wrap: wrap;
                                align-items: center;
                                .col-ul {
                                    flex-grow: 1;
                                    flex-basis: 0;
                                    margin:0px;
                                    padding: 2px 20px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-around;
                                    text-align: center;
                                    li {
                                        list-style: none;
                                        display: inline-block;
                                        width: 10%;
                                        color: #161b28;
                                        font-size: 14px;
                                        font-weight: 400;
                                        vertical-align: middle;
                                        line-height: 20px;
                                        align-items: center;
                                        &.orderId{
                                            width:10%;
                                            .icon-button{
                                                min-width: auto;
                                                width: auto;
                                                padding: 8px;
                                            }
                                        }

                                        &.customer{
                                            width:12%;
                                            position: relative;
                                            text-align: left;
                                            &::after{
                                                content: "";
                                                position: absolute;
                                                width: 1px;
                                                height: 54px;
                                                background: #e1dede;
                                                right: 0px;
                                                top: -16px;
                                            }
                                            &::before{
                                                content: "";
                                                position: absolute;
                                                width: 1px;
                                                height: 54px;
                                                background: #e1dede;
                                                right: -3px;
                                                top: -16px;
                                            }
                                            a{
                                                word-wrap: break-word;
                                                white-space: pre-line;
                                                display: block;
                                                width: 165px;
                                            }
                                        }
                                        &.bundleName{
                                            width:12%;
                                            padding: 0px 16px;
                                            .column-data{
                                                font-size: 12px;
                                                line-height: 16px;
                                            }
                                        }

                                        &.price {
                                            width:7%;
                                            line-height: 24px;
                                            .promo-code-span{
                                                font-size: 13px;
                                                display: block;
                                                word-wrap: break-word;
                                                line-height: 18px;
                                            }
                                            .pi-info-circle{
                                                top: 2px;
                                                position: relative;
                                            }
                                            [data-title]:hover:after {
                                                opacity: 1;
                                                transition: all 0.1s ease 0.4s;
                                                visibility: visible;
                                            }
                                            [data-title]:after {
                                                content: attr(data-title);
                                                background-color: #daedf2;
                                                color: #3a476a;
                                                font-size: 14px;
                                                position: absolute;
                                                bottom: -2.7em;
                                                left: 0%;
                                                white-space: nowrap;
                                                box-shadow: 1px 1px 5px rgba(231, 235, 255, 0.59);
                                                opacity: 0;
                                                border: 1px solid #daedf2;
                                                z-index: 99999;
                                                visibility: hidden;
                                                border-radius: 8px;
                                                padding: 10px;
                                            }
                                            [data-title] {
                                                position: relative;
                                            }   
                                        }

                                        .view-order{
                                            background: $white;
                                            border: 1px solid $primary;
                                            border-radius: 5px;
                                            font-weight: 400;
                                            font-size: 11px;
                                            text-transform: uppercase;
                                            color: $black;
                                            padding: 8px 8px;
                                            min-width: 123px;
                                            &:hover{
                                                background: $theme-gray;
                                                border: 1px solid $theme-gray;
                                                color: $white;
                                            }

                                            .p-button-icon{
                                                font-size: 14px;
                                            }
                                            &.icon-button{
                                                min-width: auto;
                                                width: auto;
                                            }
                                        }
                                        &.orderActions{
                                            width: 6%;
                                            display: flex;
                                            justify-content: center;
                                            .icon-button{
                                                padding: 5px;
                                                width: auto;
                                            }
                                        }
                                        &.orderStatus{
                                            .column-data{
                                                word-wrap: inherit;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .p-dataview-emptymessage {
                            text-align: center;
                            padding: 20px;
                        }
                    }
                }
            }
        }
    }
}
.refresh-btn{
    background: #f73759;
    padding: 5px 5px;
    font-size: 12px;
    cursor: pointer;
    width: auto;
    border-radius: 2px;
    border: none;
    position: relative;
    top: 2px;
}

@media (max-width:1490px){
    .admin-orderlist-section{
        .users-data-table{
            .custom-table{
                margin-top: 0px;
            }
        }
        .last-buttons-block{
            button{
                padding: 0.75rem 9px;
            }
        }
    }
}
@media (max-width:1330px){
    .admin-orderlist-section .filter-right .affiliate-dropdown .p-dropdown{
        width: 148px;
        .p-dropdown-trigger{
            width: 2rem;
        }
        .p-dropdown-label{
            font-size: 13px;
        }
    }
    .admin-orderlist-section .last-buttons-block button {
        padding: 0.75rem 6px;
    }
}

.pagination {
    display: flex;
    align-items: center;
    background: $white;
    padding: 15px 20px;
    border-radius: 0px 0px 10px 10px;
    border: 1px solid #E7EBFF;
    button {
        border: none;
        border-radius: 4px;
        background: $theme-gray;
        color: $white;
        padding: 8px 15px;
        cursor: pointer;
        text-transform: uppercase;
        font-size: 10px;
        &:hover {
            background: $black !important;
            color: $white;
        }

        &.active {
            width: auto;
            background: $theme-gray;
            padding: 6px 20px;
        }

        &.disabled {
            pointer-events: none;
            opacity: 0.3;
        }
    }

    .active {
        background: $secondary;
        margin: 0px 8px;
        width: 36px;
        text-align: center;
        padding: 4px;
        border-radius: 5px;
        color: $white;
    }
}


.p-panel .p-panel-content {
    padding: 0.5rem 1.25rem !important;
}
.p-breadcrumb ul li.p-breadcrumb-chevron{
    font-size: 13px;
}
.p-datatable {
    .p-datatable-loading-icon {
        &.p-icon {
            margin-top:30px;
        }
    }
}
.p-tooltip-bottom .p-tooltip-arrow {
    top: -2px;
    left: 50%;
    margin-left: -0.25rem;
    border-width: 0 0.5em 0.5rem;
}
.p-tooltip-text p{
    margin: 8px 0px;
    font-size: 13px;
    color: #3a476a;
}

@media (min-width:1200px) and (max-width:1700px){
    .admin-orderlist-section .filter-right {
        .p-inputtext{
            width: 115px;
            margin: 0px 5px 0px 0px;
            font-size: 13px;
        }
    }
    .admin-orderlist-section .filter-right .search-field .p-inputtext{
        width: 100px;
    }
    .admin-orderlist-section .filter-right .p-multiselect .p-multiselect-label{
        width: 130px;
        font-size: 13px;
    }
    .admin-orderlist-section .filter-right .order-status .p-multiselect .p-multiselect-label{
        width: 95px;
    }
    .admin-orderlist-section .filter-right .p-multiselect{
        margin: 0px 5px 0px 0px;
    }
    .admin-orderlist-section .filter-right .p-multiselect .p-multiselect-trigger{
        width: 1.7rem;
    }
    .admin-orderlist-section {
        padding: 20px 10px;
    }
    .admin-userlist-section, .admin-affiliateslist-section, .admin-users-section{
        padding: 20px 15px;
    }
    .admin-orderlist-section .heading-sec .exportBtn{
        margin-right: 5px;
        span{
            font-size: 13px;
        }
    }
    .admin-orderlist-section .filter-right .affiliate-dropdown .p-dropdown{
        width: 175px;
        margin-right: 5px;
    }
    .admin-orderlist-section .filter-right .p-calendar-w-btn-right .p-inputtext{
        width: 190px;
        font-size: 13px;
        padding: 5px;
    }
    .admin-orderlist-section .filter-right .search-field,
    .admin-orderlist-section .filter-right .resetBtn {
        margin-left: 5px;
    }
    .admin-orderlist-section .filter-right .p-datepicker-trigger{
        right: 5px;
    }
    .admin-orderlist-section .filter-right .p-calendar-w-btn-right{
        width: 200px;
    }
    .admin-users-section .users-data-table .p-datatable .p-datatable-wrapper .p-datatable-table .p-datatable-tbody tr td{
        font-size: 12px;
        padding: 0.6rem 0.5rem;
    }
    .admin-users-section .users-data-table .p-datatable .p-datatable-wrapper .p-datatable-table .p-datatable-thead tr th .p-column-title{
        font-size: 12px;
    }
    .users-data-table .action-btn{
        margin-right: 0px !important;
    }
    .admin-orderlist-section .users-data-table .custom-table .p-dataview .p-dataview-content .grid .custom-table-body .table-grid .col-ul{
        padding: 0px 8px;
        li{
            font-size: 13px;
            &.orderId{
                line-height: 22px;
                word-wrap: break-word;
            }
            &.paymentGateway{
                width: 10%;
            }
            &.paymentStatus{
                width: 12%;
            }
            &.customer{
                a{
                    width: 115px;
                }
            }
        }
    }
    .orderActions{
        .copyOrderId {
            margin-right: 2px !important;
        }
        .icon-button{
            font-size: 1rem !important;
            padding: 4px !important;
        }
    }
    .admin-users-section .heading-sec .export-button{
        padding: 12px 10px !important;
    }
    .admin-orderlist-section .users-data-table .custom-table .custom-table-header .table-grid .col-ul{
        padding: 5px 15px;
        margin: 10px 0px;
        li{
            font-size: 11px;
            span{
                padding: 12px 5px;
                font-size: 11px;
                .pi{
                    font-size: 11px;
                    margin-left: 1px;
                }
            }
            &.paymentGateway{
                width: 12%;
            }
            &.paymentStatus{
                width: 12%;
            }
        }
    }
    .orderStatus p .status{
        font-size: 10px !important;
        padding: 6px 5px !important;
    }
    .admin-affiliateslist-section .users-data-table .custom-table .custom-table-header .table-grid .col-ul li{
        font-size: 12px;
    }
}
@media (min-width:1200px) and (max-width:1600px){
    .admin-orderlist-section .users-data-table .custom-table .p-dataview .p-dataview-content .grid .custom-table-body .table-grid .col-ul li.price .promo-code-span{
        width: 110px;
        overflow: hidden;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .admin-users-section .users-data-table.promocode-table .p-datatable-thead .promocode-column.iccid-column.p-highlight{
        width: 195px;
    }
    .actions-tab{
        .view-order{
            padding: 8px 3px !important;
            font-size: 10px !important;
            .p-button-icon-left{
                margin-right: 0.15rem;
                font-size: 10px !important;
            }
        }
    }
    .admin-userlist-section .users-data-table .custom-table .p-dataview .p-dataview-content .grid .custom-table-body .table-grid .col-ul{
        padding: 10px 12px;
        gap: 14px;
    }
}

.file-upload-section{
    text-align: end;
    padding: 0px 0px 20px 0px;
    p{
        font-size: 14px;
        margin: 5px 0px 0px;
    }
    .file-upload-input{
        padding: 5px;
        border: 1px solid $primary;
        &::file-selector-button{
            margin-right: 15px;
            border: none;
            background:$theme-gray;
            padding: 8px 20px;
            border-radius: 5px;
            color: #fff;
            cursor: pointer;
            &:hover{
                background: $secondary;
            }
        }
    }
    .placeholder-text {
        font-weight: bold;
    }
}

.custom-table-body:hover .orderActions .copyOrderId,
.custom-table-body:hover .orderActions .orderDetailArrow {
    display: inline-block;
    cursor: pointer;
    color: red;
    font-size: 14px;
}

.orderActions .copyOrderId {
    margin-right: 8px;
}
.information-dialog{
    width: 100%;
    max-width: 470px;
    border-radius: 10px;
    .p-dialog-header{
        border-radius: 10px 10px 0px 0px;
        padding-bottom: 10px;
        .p-dialog-title{
            font-size: 24px;
            color: $theme-gray;
            text-decoration: underline;
        }
        .p-dialog-header-icon{
            position: absolute;
            top: 5px;
            right: 5px;
            .p-dialog-header-close-icon {
                font-size: 13px;
            }
            &:focus{
                box-shadow: none;
            }
        }
    }
    .p-dialog-content{
        margin-top: -1px;
        padding: 0 1.5rem 1.5rem 1.5rem;
        &:last-of-type{
            border-radius: 0px 0px 10px 10px;
        }
        .info-block{
            ul{
                li{
                    width: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    font-size: 16px;
                    font-weight: 400;
                    color: $theme-gray;
                    span{
                        margin-right: 1.2rem;
                    }
                }
            }
        }
        .colose-button{
            background: $theme-gray;
            border: none;
            border-radius: 5px;
            padding: 0.7rem 2rem;
            font-size: 14px;
            margin-top: 1rem;
            &:hover{
                background: $secondary;
            }
        }
    }
    &.qrcode-dialog{
        max-width: 400px;
        .p-dialog-header{
            background: $white;
        }
        .p-dialog-content{
            background: $white;
            .qr-code{
                text-align: center;
                padding: 2rem 0rem;
            }
        }
    }
}
.tooltip-wrapper{
    [data-title]:hover:after {
        opacity: 1;
        transition: all 0.1s ease 0.4s;
        visibility: visible;
    }
    [data-title]:after {
        content: attr(data-title);
        background-color: #daedf2;
        color: #3a476a;
        font-size: 14px;
        position: absolute;
        top: -2.8em;
        left: -4rem;
        white-space: nowrap;
        box-shadow: 1px 1px 5px rgba(231, 235, 255, 0.59);
        opacity: 0;
        border: 1px solid #daedf2;
        z-index: 99999;
        visibility: hidden;
        border-radius: 8px;
        padding: 10px;
    }
    [data-title] {
        position: relative;
    }
}
.order-details-section{
    position: relative;
    .progress-spinner{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0px;
        right: 0px;
        top: 0px;
        bottom: 0px;
        .progressSpinner{
            width: 60px;
            height: 60px;
        }
    }
}
.status {
    background: #FAFBFF;
    border-radius: 4px;
    font-weight: 400;
    font-size: 10px;
    padding: 6px 7px;
    width: 80%;
    display: block;
    margin: 0 auto;
    line-height: 120%;
    &.completed{
        color: #67b173;
        background-color: rgba(103, 177, 115, 0.15);
    }
    &.pending, &.purchase_revoked {
        color: #efb226;
        background-color: rgba(255, 200, 75, 0.15);
    }
    &.failed,  &.payment_failed, &.payment_cancelled, &.purchase_failed {
        color: #f17171;
        background-color: rgba(241, 113, 113, 0.15);
    }
    &.used {
        color: #797979;
        background-color: #D9D9D9;
    }
    &.new {
        color: $theme-gray;
        background-color: #b6c3e7;
    }
    &.paid {
        color: $theme-gray;
        background-color: #c1deea;
    }
    &.refunded  {
        color: $white;
        background-color: #008dda;
    }
}
.status-order {
    background: #FAFBFF;
    border-radius: 4px;
    font-weight: 400;
    text-align: center;
    font-size: 10px;
    padding: 6px 7px;
    width: 80%;
    display: block;
    margin: 0 auto;
    line-height: 120%;
    &.completed{
        color: #67b173;
        background-color: rgba(103, 177, 115, 0.15);
    }
    &.pending, &.purchase_revoked {
        color: #efb226;
        background-color: rgba(255, 200, 75, 0.15);
    }
    &.failed,  &.payment_failed, &.payment_cancelled, &.purchase_failed {
        color: #f17171;
        background-color: rgba(241, 113, 113, 0.15);
    }
    &.used {
        color: #797979;
        background-color: #D9D9D9;
    }
    &.new {
        color: $theme-gray;
        background-color: #b6c3e7;
    }
    &.paid {
        color: $theme-gray;
        background-color: #c1deea;
    }
    &.refunded  {
        color: $white;
        background-color: #008dda;
    }
}
.voucherCodeDisplay{
    display: grid;
    grid-template-columns: max-content max-content;
    grid-gap: 8px;
    align-items: flex-end;
   
}
.esim {
    display:block;
    font-style:italic;
    color:$secondary;
    padding:5px 0px;
    font-size: 14px;

    .esimCompatible {
        color:$green;
    }

    .esimNonCompatible {
        color:$secondary;
    }
}

.copyIcon{
    background: none;
    border: none;
    padding: 0px;
    width: auto;
    margin: 0px 0px 0px 10px;
    .p-button-icon{
        color: $secondary;
        font-size: 20px;
    }
}

.save-button{
    padding: 14px 23px;
    border-radius: 6px;
    background:$theme-gray;
    border: none;
    height: 40px;
    .p-button-label{
        font-size: 15px;
        font-weight: 600;
    }
    &:hover{
        background: $secondary !important;
    }
}
@media (min-width: 1701px) and (max-width: 1860px) {
    .admin-orderlist-section {
        .filter-right{
            .p-datepicker-trigger{
                right: 5px;
            }
            .p-multiselect{
                margin: 0px 5px 0px 0px;
            }
            .resetBtn{
                margin-left: 5px;
            }
            .p-inputtext{
                margin: 0px 5px 0px 0px;
                width: 130px;
            }
            .users-search-input{
                .p-dropdown-trigger{
                    width: 2rem;
                }
            }
            .affiliate-dropdown .p-dropdown{
                width: 160px;
            }
            .p-multiselect .p-multiselect-label{
                width: 130px;
            }
        }
        .users-data-table .custom-table .custom-table-header .table-grid .col-ul li{
            font-size: 13px;
            span{
                padding: 12px 5px;
            }
        }
    }
}
.affiliate-dialog-mask{
    align-items: center !important;
    .affiliate-dialog{
        width: 90%;
        max-width: 420px;
        .p-dialog-header{
            border-radius: 10px 10px 0px 0px;
            padding: 2rem 2rem 0rem;
            background: #F4F6FE;
            .p-dialog-header-icon{
                position: absolute;
                right: 7px;
                top: 7px;
                .p-dialog-header-close-icon{
                    font-size: 13px;
                }
                &:focus{
                    box-shadow: none;
                }
            }
            .p-dialog-title{
                color: #3E4768;
                font-size: 24px;
                font-weight: 700;
                line-height: 120%;
                text-decoration-line: underline;
            }
        }
    .p-dialog-content{
            border-radius: 0px 0px 10px 10px;
            padding: 0rem 2rem 2rem;
            background: #F4F6FE;
            &:last-of-type{
                border-radius: 0px 0px 10px 10px;
            }
            p, label{
                color: $black;
                font-size: 16px;
                font-weight: 400;
                line-height: 120%;
            }
            .days-input{
                input{
                    width: 70px;
                    margin-left: 8px;
                    padding: 8px 10px;
                    text-align: center;
                }
            }
            .percentage-input{
                input{
                    width: 70px;
                    margin-left: 118px;
                    padding: 8px 10px;
                    text-align: center;
                }
            }
            .buttons-sections {
                margin-top: 2rem;
                .confirm-button{
                    padding: 14px 23px;
                    border-radius: 6px;
                    background:$theme-gray;
                    border: none;
                    .p-button-label{
                        font-size: 15px;
                        font-weight: 600;
                    }
                    &:hover{
                        background: $secondary;
                    }
                    &.reset-btn{
                        background: #D9D9D9;
                        cursor: pointer;
                        .p-button-label{
                            color: $black;
                        }
                        &:hover{
                            background: $secondary;
                            .p-button-label{
                                color: $white;
                            }
                        }
                    }
                    &.min-width{
                        min-width: 123px;
                    }
                }
            }
    }
        .p-dropdown{
            width: 195px;
            text-align: left;
            height: 35px;
            border: 1px solid $blue-light;
            position: relative;
            top: 2px;
            .p-dropdown-label{
                font-family: $font-base;
                font-size: 12px;
                color: $black;
                padding:9px 12px;
            }
            .p-dropdown-trigger{
                .pi{
                    font-size: 12px;
                }
            }
        }
        .p-checkbox{
            .p-checkbox-box{
                width: 17px;
                min-width: 17px;
                height: 17px;
                border-radius:3px;
                border: 1px solid #C4C4C4;
                &.p-highlight{
                    background: transparent;
                    &:hover{
                        background: none;
                    }
                }
                .p-checkbox-icon{
                    color: $grey-dark;
                    font-weight: 600;
                    font-size: 11px;
                }
                &.p-focus{
                    box-shadow: none;
                }
            }
        }
        label{
            font-size: 13px;
        }
        p{
            font-size: 14px;
            font-weight: 700;
        }
        &.affiliate-assign{
            max-width: 500px;
            .p-dialog-header .p-dialog-title{
                text-decoration: none;
            }
            .confirm-button{
                width: 100%;
                padding: 12px 20px !important;
            }
        }
    }
}
.esims-tabs{
    .p-tabview-panels{
        padding: 0px;
        background: none;
    }
    .p-tabview-nav{
        display: flex;
        align-items: center;
        background: none;
        border: none !important;
        border-radius: 4px;
        padding: 7px 7px 9px;
        justify-content: flex-end;
        gap:3px;
        .p-unselectable-text{
            .p-tabview-nav-link{
                border-radius: 4px;
                color: #06070a;
                font-size: 14px;
                font-weight: 500;
                padding: 8px 20px;
                background: #fff;
                border: 1px solid $white;
                &:hover{
                    border: 1px solid $theme-gray;
                }
            }
            &.p-tabview-selected .p-tabview-nav-link{
                background: $theme-gray;
                box-shadow: 2px 4px 18px 0 #6f6e9726;
                border: 1px solid $theme-gray;
                .p-tabview-title{
                    color: $white;
                }
            }
        }
        li .p-tabview-nav-link:not(.p-disabled):focus{
            box-shadow: none;
        }
    }
    .p-tabview-ink-bar{
        display: none !important;
    }
    .users-data-table{
        .progress-bar{
            height: 14px;
            width: 100%;
            max-width: 85%;
            margin: 0px auto;
            .p-progressbar-label{
                font-size: 10px;
            }
            .p-progressbar-value{
                background: linear-gradient(90deg, #3a476a 4.57%, #6074ab 89.96%);
            }
        }
        .p-datatable-row-expansion{
            > td{
                padding: 0px !important;
            }
            .p-datatable-thead{
                display: none;
            }
            .p-datatable-tbody{
                height: auto;
                tr{
                    td{
                        background: #f7f6f6;
                    }
                }
            }
        }
        .p-row-toggler{
            width: 1.5rem !important;
            height: 1.5rem !important;
            .p-row-toggler-icon{
                color: $secondary;
                font-size: 14px;
            }
        }
    }
    .last-column{
        .pi-info-circle{
            &:hover{
                color: $secondary;
                cursor: pointer;
            }
        }
    }
}
.myplans-section{
    padding: 0rem;
    background: $white;
    .loading-div{
        .pi-spinner{
            color: $black;
            font-size: 1.8rem;
        }
    }
    .bundles-list{
        text-align: left;
        .bundle{
            border-radius: 10px;
            background: #F3F6FF;
            padding: 20px;
            color: $black;
            border: 1px solid #e1e4f5;
            .dot{
                width: 30px;
                height: 30px;
                min-width: 30px;
                border-radius: 25px;
                background: #e1e4f5;
                display: flex;
                align-items: center;
                justify-content: center;
                .active{
                    width: 12px;
                    height: 12px;
                    background: #19FF15;
                    display: block;
                    border-radius: 10px;
                }
                .pending{
                    width: 12px;
                    height: 12px;
                    background: #7A7A7A;
                    display: block;
                    border-radius: 10px;
                }
                .expired{
                    width: 12px;
                    height: 12px;
                    background: #FFA600;
                    display: block;
                    border-radius: 10px;
                }
            }
            h2{
                margin: 0px;
                font-size: 25.43px;
                font-weight: 600;
                line-height: 160%;
                display: flex;
                gap: 16px;
            }
            p{
                margin: 0px;
                font-size: 16px;
                font-weight: 400;
                line-height: 160%;
            }
            h3{
                margin: 0px;
                font-size: 18.753px;
                font-weight: 400;
                line-height: 160%;
                color: #DBE0ED;
            }
            h4{
                margin: 0px;
                color: $grey-dark;
                font-size: 18px;
                font-weight: 500;
                line-height: 160%;
                span{
                    color: $white;
                    font-weight: 500;
                }
            }
            .progress-bar{
                height: 11px;
                border-radius: 25px;
                .p-progressbar-value{
                    background: linear-gradient(90deg, #3a476a 4.57%, #6074ab 89.96%);
                    border-radius: 25px;
                    .p-progressbar-label{
                        display: none;
                    }
                }
            }
            .warning-msg{
                border-radius: 10.716px;
                border: 1.34px solid #EDD6D4;
                background: rgba(230, 214, 212, 0.90);
                padding: 8.037px 24.111px;
                margin-top: 2.3125rem;
                text-align: left;
                p{
                    color: #C2251A;
                    font-size: 18.753px;
                    font-weight: 500;
                    line-height: 160%;
                    margin: 0px;
                }
                .p-image{
                    line-height: 0px;
                }
                &.expired{
                    h3{
                        margin: 0px;
                        color: #2A3244;
                        font-size: 18px;
                        font-weight: 500;
                        line-height: 160%;
                    }
                    h4{
                        margin: 0px;
                        color: #505A6F;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 160%;
                    }
                }
            }
        }
    }
    .bundle-details-section{
        width: 100%;
        max-width: 1255px;
        margin: 0 auto;
        .heading-p{
            font-size: 18px;
            font-weight: 600;
            line-height: 160%;
            color: $black;
            margin: 0px;
            padding: 10px 0px;
        }
        .order-details-block{
            border-radius: 10px;
            background: #F3F6FF;
            padding: 20px;
            border: 1px solid #e1e4f5;
            ul{
                margin: 0px;
                padding: 1rem 0rem;
                border-bottom: 1px solid #e1e4f5;
                &:first-child{
                    padding: 0rem 0rem 0.6rem;
                }
                &:last-child{
                   border-bottom: 0px;
                   padding: 1rem 0rem 0rem;
                }
                li{
                    list-style: none;
                    color: $black;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 160%;
                    &:last-child{
                        color: $theme-gray;
                        font-weight: 500;
                        text-align: right;
                        line-height: 160%;
                    }
                    .completed{
                        border-radius: 8px;
                        background: #E1F4E2;
                        padding: 8px 15px;
                        color: #0C8F09;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 160%;
                        border: 1px solid;
                    }
                    .download-button{
                        border-radius:10px;
                        border: none;
                        background: #F1F3F5;
                        padding: 12px 22px 12px 20px;
                        .p-button-label{
                            color: #242424;
                            font-size: 17.468px;
                            font-weight: 500;
                            line-height: 160%;
                        }
                        .p-button-icon{
                            color: #242424;
                            font-weight: 600;
                        }
                        &:hover{
                            background: $secondary;
                            .p-button-label{
                                color: $white;
                            }
                            .p-button-icon{
                                color: $white;
                            }
                        }
                    }
                }
            }
        }
        .sticky-block{
            position: sticky;
            top: 0px;
        }
    }
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item{
    font-size: 14px;
}
.esim-details{
    .p-dialog{
        width: 92%;
        max-width: 1060px;
        height: 680px;
    }
    .p-dialog-content{
        &::-webkit-scrollbar {
            width: 6px;
            border-radius: 10px;
        }
        &::-webkit-scrollbar-track {
            background: #f3f6ff;
            border-radius: 10px;
        }
        &::-webkit-scrollbar-thumb {
            background: #CBCBCB;
            padding: 2px;
            border-radius: 10px;
        }
    }
    .main{
        background: $white;
    }
}
.payment-id-column{
    word-wrap: break-word;
    word-break: break-all;
}

.mobile-filter-section{
    background: $theme-gray;
    padding: 1rem;
    border-radius: 5px;
    form{
        width: 100%;
    }
    .mobile-flex{
        flex-direction: column;
        align-items: flex-start !important;
        gap: 10px;
        .users-search-input{
            width: 100%;
            .p-inputtext, .p-multiselect, .p-dropdown, .p-calendar{
                width: 100%;
                margin: 0px;
            }
            .p-datepicker-trigger{
                right: 0px;
            }
            &.email-input{
                .p-inputtext{
                    width: 100%;
                    min-width: auto !important;
                }
            }
            &.Promocode-select .p-dropdown{
                width: 100%;
            }
        }
    }
    .searchBtn{
        background: $primary !important;
        span{
            color: $black;
        }
    }
    &.mt-5{
        margin-top: 20px !important;
    }
}
.filter-toggle-button{
    width: 40px;
    height: 40px;
    background: $theme-gray;
    padding: 0px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    display: none;
    &:hover{
        background: $secondary !important;
    }
    &:enabled:active{
        background: $theme-gray;
    }
    &:focus{
        box-shadow: none;
    }
    .pi{
        font-size: 14px;
        color: $white;
    }
}
.column-label{
    display: none;
}
.column-data{
    word-wrap: break-word;
    word-break: break-word;
    &.mobile-flex{
        display: flex;
        gap: 4px;
    }
}
.affiliate-table-body{
    padding: 0px 20px;
    li{
        &.actionsColumn{
            padding-left: 0px !important;
        }
        &.affiliateUserEmail{
            .column-data{
                width: 90%;
                line-height: 105%;
            }
        }
    }
}
.affiliate-table-header{
    padding: 0px 20px;  
    .enable-api{
        width: 10% !important;
    }
}
.create-affiliate{
    .p-chips-multiple-container{
        width: 100%;
        li{
            padding: 0.34rem 0 !important;
        }
        .p-chips-input-token input{
            font-size: 15px;
        }
    }
    .days-input,.percentage-input{
        width: 100%;
    }
    .label-position{
        position: absolute;
        right: 1rem;
        top: 38px;
    }
    .label-text{
        position: relative;
        top: -5px;
        margin-right: 10px;
    }
    .p-component{
        &::placeholder{
            font-weight: 500;
            font-size: 15px;
        }
    }
    .react-tel-input{
        .form-control{
            width: 100%;
            padding: 0.75rem 4rem;
            border: 1px solid #ced4da;
            border-radius: 6px;
            height: 45px;
        }
        .flag-dropdown{
            border-radius: 6px 0 0 6px;
            .selected-flag{
                border-radius: 6px 0 0 6px;
            }
        }
    }
}

// Responsive CSS

@media (max-width:768px){
    .admin-orderlist-section{
        .filter-right{
            display: none;
            &.mobile-filter-section{
                display: block;
            }
        }
        .last-buttons-block button{
            padding: 0.8rem 20px;
        }
        .heading-sec .exportBtn{
            padding: 0.7rem 1rem;
            &.filter-btn{
                margin-top: 0.6rem;
            }
        }
        .users-data-table{
            .custom-table{
                .custom-table-header{
                    display: none;
                }
                .p-dataview .p-dataview-content{
                    height: calc(100vh - 260px);
                    overflow-x: hidden;
                    .grid .custom-table-body .table-grid{
                        .mobile-col-ul{
                            display: block;
                            text-align: left;
                            background: #F5F5F5;
                            border-radius: 8px;
                            margin: 10px;
                            padding: 10px 15px;
                            li{
                                width: 100%;
                                display: flex;
                                margin: 10px 0px;
                                &.customer{
                                    width: 100%;
                                    &::before{
                                        display: none;
                                    }
                                    &::after{
                                        display: none;
                                    }
                                }
                                &.orderId{
                                    width: 100%;
                                }
                                &.bundleName{
                                    width: 100%;
                                    padding: 0px 0px;
                                }
                                &.price{
                                    width: 100%;
                                }
                                &.orderActions{
                                    width: 100%;
                                    justify-content: start;
                                }
                                .status{
                                    margin: 0px;
                                    text-align: center;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .filter-toggle-button{
        display: block;
    }
    .column-label{
        display: block;
        flex: 1 0 150px;
        position: relative;
        font-weight: 600;
        &:after{
            content: ':';
            position: absolute;
            width: 5px;
            height: 5px;
            color: #000;
            right: 1rem;
            top: 0px;
            font-size: 14px;
            z-index: 99;
            font-weight: 600;
        }
    }
    .column-data{
        flex: 1 0 150px;
        text-align: left;
        word-break: break-all;
        p{
            margin: 0px;
        }
        .ml-1{
            margin-left: 0px !important;
        }
        &.mobile-flex{
            flex-direction: column;
            .p-button{
                .p-button-label{
                    flex: none;
                }
            }
        }
    }
    .datte-range-calendar{
        width: 80% !important;
        margin: 0 auto;
        right: 0px;
        left: 0px !important;
        .p-datepicker-group-container{
            flex-direction: column;
        }
    }
    .breadcrumb-section{
        display: none;
    }
    .mobile-breadcrumb{
        margin-left: 0px;
        margin-top: 10px;
    }
    .admin-users-section{
        .users-data-table{
            .order-details-sec{
                padding: 15px;
                .list-box{
                    .mobile-flex{
                        flex-direction: column;
                        align-items: flex-start !important;
                        .right{
                            flex-direction: column;
                            width: 100%;
                            margin-top: 5px;
                            p{
                                font-size: 13px;
                            }
                            .status{
                                padding: 5px 5px;
                                font-size: 10px;
                            }
                            .flex{
                                width: 100%;
                            }
                            .icon-button{
                                margin: 0px;
                            }
                        }
                    }
                    .user-data-ul{
                        li{
                            width: 100%;
                            display: flex;
                            span{
                                flex: 1 0 110px;
                                &.user-data-span{
                                    flex: 1 0 150px;
                                }
                            }
                        }
                    }
                }
                .statusFields{
                    padding: 5px 5px;
                    font-size: 10px;
                    margin-left: 0px;
                }
                .status-order{
                    margin: 0px;
                }
            }
        }
    }
    .admin-userlist-section{
        .heading-sec{
            .filter-right{
                display: none;
                &.mobile-filter-section{
                    display: block;
                }
            }
        }
        .users-data-table{
            .custom-table{
                .custom-table-header{
                    display: none;
                }
                .p-dataview .p-dataview-content{
                    height: calc(100vh - 240px);
                    overflow-x: hidden;
                    .grid{
                        .custom-table-body .table-grid{
                            .mobile-col-ul{
                                display: block !important;
                                text-align: left;
                                background: #F5F5F5;
                                border-radius: 8px;
                                margin: 10px;
                                padding: 10px 15px;
                                li{
                                    width: 100% !important;
                                    display: flex;
                                    margin: 10px 0px;
                                    text-align: left !important;
                                    .column-label{
                                        flex: 1 0 80px;
                                    }
                                    &.customer{
                                        width: 100%;
                                        &::before{
                                            display: none;
                                        }
                                        &::after{
                                            display: none;
                                        }
                                    }
                                    &.orderId{
                                        width: 100%;
                                    }
                                    &.bundleName{
                                        width: 100%;
                                        padding: 0px 0px;
                                    }
                                    &.price{
                                        width: 100%;
                                    }
                                    &.orderActions{
                                        width: 100%;
                                        justify-content: start;
                                    }
                                    .status{
                                        margin: 0px;
                                        text-align: center;
                                    }
                                }
                            }
                        }
                        .custom-bg:nth-child(even){
                            background: none;
                        }
                    }
                }
            }
        }
    }
    .admin-affiliateslist-section{
        .heading-sec{
            .filter-right{
                display: none;
                &.mobile-filter-section{
                    display: block;
                    margin-top: 10px;
                }
                .export-button{
                    background: $black;
                    margin-top: 10px;
                }
            }
            .m-flex{
                flex-direction: column;
                align-items: flex-start !important;
                .right-section{
                    justify-content: space-between !important;
                    width: 100%;
                }
            }
        }
        .users-data-table{
            .custom-table{
                .custom-table-header{
                    display: none;
                }
                .p-dataview .p-dataview-content .grid{
                    .custom-bg:nth-child(even){
                        background: none;
                    }
                    .custom-table-body .table-grid{
                        display: block;
                        border-bottom: 0px;
                        .col-ul{
                            &.mobile-col-ul{
                                display: block !important;
                                text-align: left;
                                background: #F5F5F5;
                                border-radius: 8px;
                                margin: 10px;
                                padding: 10px 15px;
                                li{
                                    width: 100% !important;
                                    display: flex;
                                    margin: 10px 0px;
                                    text-align: left !important;
                                    &.actionsColumn{
                                        padding-left: 0px;
                                    }
                                    &.affiliateId{
                                        overflow:inherit;
                                        white-space: normal;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .promoCode-column{
        background-color: transparent;
        padding: 0px;
        border: none;
    }
    .refresh-btn{
        padding: 3px 5px 4px;
        top: 1px;
    }
}

@media (min-width:769px) and (max-width:960px){
    .filter-toggle-button{
        display: block;
    }
    .admin-orderlist-section, .admin-affiliateslist-section{
        .filter-right {
            display: none !important;
            &.mobile-filter-section{
                display: block !important;
            }
        }
        .users-data-table .custom-table{
            .p-dataview .p-dataview-content{
                height: calc(100vh - 260px);
                overflow: auto;
                .grid .custom-table-body .table-grid{
                    .mobile-col-ul{
                        display: block;
                        text-align: left;
                        background: #F5F5F5;
                        border-radius: 8px;
                        margin: 10px;
                        padding: 10px 15px;
                        li{
                            width: 100% !important;
                            display: flex;
                            margin: 10px 0px;
                            text-align: left !important;
                            &.customer{
                                width: 100%;
                                &::before{
                                    display: none;
                                }
                                &::after{
                                    display: none;
                                }
                            }
                            &.orderId{
                                width: 100%;
                            }
                            &.bundleName{
                                width: 100%;
                                padding: 0px 0px;
                            }
                            &.price{
                                width: 100%;
                            }
                            &.orderActions{
                                width: 100%;
                                justify-content: start;
                            }
                            .status{
                                margin: 0px;
                                text-align: center;
                            }
                            &.actionsColumn{
                                padding-left: 0px;
                            }
                        }
                    }
                }
            }
            .custom-table-header .table-grid{
                display: none;
            }
        }
    }
    .column-label {
        display: block;
        flex: 1 0 150px;
        position: relative;
        font-weight: 600;
        &:after{
            content: ':';
            position: absolute;
            width: 5px;
            height: 5px;
            color: #000;
            right: 1.5rem;
            top: 0px;
            font-size: 14px;
            z-index: 99;
            font-weight: 600;
        }
    }
    .column-data {
        flex: 1 0 150px;
        text-align: left;
        word-break: break-all;
        white-space: break-spaces;
    }
    .p-menubar .p-menubar-button{
        .pi-bars{
            display: none;
        }
    }
    .order-details-section{
        .mobile-flex{
            flex-direction: column;
            align-items: flex-start !important;
            .right{
                flex-direction: column;
                align-items:flex-start !important;
                .icon-button{
                    margin: 0px 0px 0px 0px !important;
                }
            }
        }
        .order-details-sec{
            .md\:col-6{
                width: 100%;
            }
        }
    }
    .breadcrumb-section{
        display: none;
    }
    .admin-userlist-section{
        .heading-sec .filter-right{
            display: none;
            &.mobile-filter-section{
                display: block;
            }
        }
        .users-data-table .custom-table{
            .p-dataview .p-dataview-content{
                height: calc(100vh - 220px);
                overflow: auto;
                .grid .custom-table-body .table-grid{
                    .mobile-col-ul{
                        display: block !important;
                        text-align: left;
                        background: #F5F5F5;
                        border-radius: 8px;
                        margin: 10px;
                        padding: 10px 15px;
                        li{
                            width: 100% !important;
                            display: flex;
                            margin: 10px 0px;
                            &.customer{
                                width: 100%;
                                &::before{
                                    display: none;
                                }
                                &::after{
                                    display: none;
                                }
                            }
                            &.orderId{
                                width: 100%;
                            }
                            &.bundleName{
                                width: 100%;
                                padding: 0px 0px;
                            }
                            &.price{
                                width: 100%;
                            }
                            &.orderActions{
                                width: 100%;
                                justify-content: start;
                            }
                            .status{
                                margin: 0px;
                                text-align: center;
                            }
                            &.affiliateUser{
                                text-align: left;
                            }
                        }
                    }
                }
            }
            .custom-table-header .table-grid{
                display: none;
            }
        }
    }
    .transaction-history-datatable{
        .status-order{
            width: auto;
            margin: 0px;
        }
    }
    .admin-users-section .users-data-table .p-datatable .p-datatable-wrapper{
        overflow-x: hidden;
    }
    .promoCode-column{
        background-color: transparent;
        padding: 0px;
        border: none;
    }
}

@media (min-width:1200px) and (max-width:1370px){
    .admin-affiliateslist-section .heading-sec{
        .filter-right{
            .users-search-input{
                width: 150px;
                margin-right: 5px;
                .p-multiselect .p-multiselect-label{
                    width: 100px;
                }
                &.laptop-btns-block{
                    width: 175px;
                }
            }
            .p-inputtext{
                width: 100%;
            }
           .export-button{
                padding: 12px 15px;
                .p-button-label{
                    font-size: 13px;
                }
           }
        }
        .resetBtn{
            margin-left: 5px;
        }
    }
    .admin-userlist-section .heading-sec{
        .filter-right{
            .users-search-input{
                margin-right: 5px;
                &.email-input .p-inputtext{
                    min-width: 180px;
                }
                .p-dropdown{
                    width: 165px;
                    font-size: 13px;
                }
            }
            .p-inputtext{
                font-size: 13px;
                letter-spacing: -0.3px;
                padding-left: 0.5rem;
                padding-right: 0.5rem;
            }
            .p-calendar-w-btn-right.customers-daterange{
                width: 12rem;
            }
        }
        .searchBtn{
            padding: 0.75rem 12px;
        }
        .resetBtn{
            margin-left: 5px;
            padding: 0.75rem 12px;
        }
    }
}

@media (min-width:1371px) and (max-width:1575px){
    .admin-userlist-section .heading-sec{
        .filter-right{
            .users-search-input{
                margin-right: 6px;
                &.email-input .p-inputtext{
                    min-width: 195px;
                }
                .p-dropdown{
                    width: 175px;
                    font-size: 13px;
                }
            }
            .p-inputtext{
                font-size: 14px;
                letter-spacing: -0.2px;
                padding-left: 0.5rem;
                padding-right: 0.5rem;
            }
            .p-calendar-w-btn-right.customers-daterange{
                width: 13rem;
            }
        }
        .searchBtn{
            padding: 0.75rem 14px;
        }
        .resetBtn{
            margin-left: 6px;
            padding: 0.75rem 14px;
        }
    }
}
@media (min-width: 1200px) and (max-width: 1555px) {
    .admin-orderlist-section{
        .filter-right{
            .mobile-flex{
                flex-wrap: wrap;
                gap: 1px;
            }
            .email-orderid-iccid{
                .mobile-flex{
                    flex-wrap: nowrap;
                } 
            }
        }
        .last-buttons-block button {
            padding: 0.75rem 15px;
        }
    }
    .admin-userlist-section{
        .users-data-table{
            .custom-table{
                .p-dataview{
                    .p-dataview-content{
                        .grid .custom-table-body .table-grid .col-ul{
                            padding: 10px 12px;
                            gap: 15px;
                            li{
                                .view-order{
                                    font-size: 10px;
                                    .p-button-icon-left{
                                        font-size: 10px !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
@media (min-width: 1200px) and (max-width: 1345px) {
    .admin-orderlist-section{
        .filter-right {
            position: relative;
            .resetBtn{
                &.hide-show-btn{
                    position: relative;
                    top: 8px;
                    padding: 0.75rem 0.9rem;
                    margin-left: 0px;
                }
            }
            .moreFilters-block{
                position: absolute;
                left: 8rem;
                top: 2.3rem;
            }
            .p-multiselect .p-multiselect-label{
                width: 135px;
            }
            .affiliate-dropdown .p-dropdown{
                width: 175px !important;
            }
        }
    }
}
@media (max-width:960px){   
    .admin-userlist-section .heading-sec{
        .filter-right{
            .p-calendar-w-btn-right{
                &.customers-daterange{
                    width: 100%;
                }
            }
        }
    }
    .admin-affiliateslist-section{
        .heading-sec{
            .filter-right{
                .p-calendar-w-btn-right.customers-daterange{
                    width: 100%;
                }
            }
        }
    }
    .plan-detials-datatable{
        .p-datatable-wrapper{
            .p-datatable-thead{
                display: none;
            }
            .p-datatable-tbody{
                tr{
                    background: #F5F5F5;
                    display: grid;
                    td{
                        border: 1px solid #efecec !important;
                        position: relative;
                        padding-left: 46% !important;
                        width: 100% !important;
                        .p-column-title{
                            display: none;
                        }
                        &:before {
                            position: absolute;
                            left: 10px;
                            width: 50%;
                            padding-right: 10px;
                            white-space: nowrap;
                            font-size: 13px;
                            font-weight: 600;
                            top: 30%;
                        }
                        &:after {
                            content: ':';
                            position: absolute;
                            left: 40%;
                            font-size: 14px;
                            font-weight: 600;
                            top: 30%;
                        }
                        &:nth-of-type(1):before { content: "Provider Name"; }
                        &:nth-of-type(2):before { content: "Plan"; }
                        &:nth-of-type(3):before { content: "Bundle Name"; }
                        &:nth-of-type(4):before { content: "Order Type"; }
                        &:nth-of-type(5):before { content: "Purchased On"; }
                        &:nth-of-type(6):before { content: "Expiry On"; }
                        &:nth-of-type(7):before { content: "Price"; }
                    }
                }
            }
        }
    }
    .transaction-history-datatable{
        @extend .plan-detials-datatable;
        .p-datatable-wrapper{
            .p-datatable-thead{
                display: none;
            }
            .p-datatable-tbody{
                tr{
                    display: grid;
                    td{
                        width: 100% !important;
                        &:nth-of-type(1):before { content: "Transaction ID"; }
                        &:nth-of-type(2):before { content: "Payment ID"; }
                        &:nth-of-type(3):before { content: "Value"; }
                        &:nth-of-type(4):before { content: "Payment Gateway"; }
                        &:nth-of-type(5):before { content: "Date"; }
                        &.promo-voucher{&:before { content: "Promo / Voucher";}}
                        &.tstatus{&:before { content: "Transaction status";}}
                        &::after{top: 30%;}
                    }
                }
            }
        }
    }
}

.promocode-tooltip{
    .p-tooltip-text{
        background: $primary;
        color: $black;
    }
    .p-tooltip-arrow{
        border-bottom-color: $primary !important;
    }
}

@media (min-width:961px) and (max-width:1180px){
    .order-details-sec{
        .list-box{
            .mobile-flex{
                flex-direction: column;
            }
        }
    }
    .dashboard_section {
        .current-previous-btns-wrapper{
            position: inherit !important;
            top: 18px !important;
        }
        .col-custom-width{
            width: 50%;
        }
    }
}
@media (max-width:420px){  
    .admin-userlist-section{
        padding: 15px;
        .users-data-table {
            .custom-table{
                .p-dataview {
                    .p-dataview-content {
                        .grid {
                            .custom-table-body {
                                .table-grid {
                                    .col-ul li.affiliateUser{
                                        .affiliate-edit{
                                            padding: 5px;
                                            max-width: 150px;
                                            margin: 0px;
                                            .affiliate-name{
                                                font-size: 12px;
                                            }
                                            .p-button{
                                                margin-left: 2px;
                                            }
                                        }
                                        .p-dropdown{
                                            max-width: 165px;
                                            .p-dropdown-label{
                                                font-size: 12px;
                                                padding: 5px 6px;
                                            }
                                            .p-dropdown-clear-icon{
                                                right: 2rem;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
@media (min-width:1601px) and (max-width:1850px){
    .admin-userlist-section .users-data-table .custom-table {
        .p-dataview .p-dataview-content .grid .custom-table-body{
            .table-grid .col-ul li {
                .view-order{
                    padding: 8px 6px;
                    font-size: 10px;
                }
                .p-button .p-button-icon-left {
                    margin-right: 0.2rem;
                }
            }
        }
    }
}
@media (min-width:1280px) and (max-width:1371px){
    .admin-userlist-section .users-data-table .custom-table .p-dataview .p-dataview-content .grid .custom-table-body {
        .table-grid .col-ul li{
            &.affiliateUser .affiliate-edit .affiliate-name{
                font-size: 12px;
            }
            .column-data.mobile-flex{
                flex-direction: column;
            }
            &.actions-tab .view-order{
                padding: 6px 8px !important;
                font-size: 11px !important;
            }
        }
    }
}
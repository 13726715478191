@import '../../../assets/scss/variables';

.faq-section{
    .image-preview{
        margin-left: 0px;
        margin-top: 5px;
    }
}
.faq-header{
    h2{
        margin: 0px;
    }
    p{
        margin: 8px 0px 0px;
    }
    .language-search-field{
        min-width: 230px;
        border: 1px solid #495057;
        border-radius: 6px;
        height: 40px;
        padding-left: 2.2rem;
        &::placeholder{
            font-weight: 500;
        }
    }
}

.dropdown{
    .p-dropdown{
        min-width: 180px;
        border: 1px solid #495057;
        .p-dropdown-label{
            border: none;
            height: 38px;
            padding: 10px 0.75rem;
        }
        .p-dropdown-trigger{
            .p-dropdown-trigger-icon{
                font-size: 13px;
            }
        }
    }
    .pi-search{
        position: absolute;
        left: 12px;
        top: 13px;
        color: #888;
        z-index: 99;
        font-size: 15px;
    }
}
.category-block{
    border-radius: 6px;
    border: 1px solid #EEE;
    padding: 10px 50px 10px 15px;
    color: #3E3F6C;
    font-family: Lato;
    font-size: 15px;
    font-style: italic;
    font-weight: 500;
    line-height: 120%;
    position: relative;
}
.category-field{
    border-radius: 6px !important;
    border: 1px solid #EEE !important;
    height: 40px !important;
    min-width: 250px;
    font-size: 15px !important;
    padding: 0.75rem 2.2rem 0.75rem 0.75rem;
    &::placeholder{
        color: #aaa;
        font-weight: 500;
        font-size: 16px;
    }
}
.close-pi{
    width: 20px;
    height: 20px;
    right: 10px;
    font-size: 10px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 10px;
    &:hover{
        background: $secondary !important;
        cursor: pointer;
        color: #fff !important;
    }
    &.tab-pi{
        position: absolute;
        background: #3E3F6C;
        color: #fff;
    }
}
.add-category-btn{
    background: #3E3F6C;
    border: none;
    border-radius: 6px;
    height: 40px;
    span {
        font-weight: 500;
        font-size: 15px;
    }
    &:hover {
        background: $secondary !important;
    }
}
.add-edit-faq{
    .faq-block{
        padding: 16px 17px;
        border-radius: 8px;
        border: 1px solid #D9D9D9;
        background: #FFF;
        margin-top: 1rem;
        .title-input-field{
            border-radius: 4px;
            border: 1px solid #D9D9D9;
            background: #FFF;
            height: 45px;
            &::placeholder{
                color: #aaa;
                font-weight: 500;
                font-size: 16px;
            }
        }
        .p-dropdown{
            min-width: 180px;
            border: 1px solid #D9D9D9;
            width: 100%;
            border-radius: 4px;
            .p-dropdown-label{
                border: none;
                height: 43px;
                padding: 12px 0.75rem;
            }
            .p-dropdown-trigger{
                .p-dropdown-trigger-icon{
                    font-size: 13px;
                }
            }
        }
        &.faq-editing{
            background: #e9e9f5;
            border: 1px solid #d5d5f3;
            .ql-toolbar{
                opacity: 1;
                cursor: default;
                pointer-events: unset;
                -webkit-user-select: unset;
                user-select: unset;
            }
        }
        &.editor-disable{
            opacity: 0.6;
            cursor: default;
            pointer-events: none;
            -webkit-user-select: none;
            user-select: none;
        }
        .ql-disabled{
            .ql-editor{
                opacity: 0.6;
                cursor: default;
                pointer-events: none;
                -webkit-user-select: none;
                user-select: none;
            }
        }
    }
}
.editor-block{
    .p-editor-container{
        position: relative;
    }
    .ql-editor{
        border-radius: 4px;
        background: #FFF;
        font-size: 14px;
        font-family: "Lato", sans-serif;
        padding-bottom: 4.5rem;
        font-size: 14px;
        font-family: "Lato", sans-serif;
        &::-webkit-scrollbar {
            width: 6px;
            border-radius: 10px;
        }
        &::-webkit-scrollbar-track {
            background: #f3f6ff;
            border-radius: 10px;
        }
        &::-webkit-scrollbar-thumb {
            background: #CBCBCB;
            padding: 2px;
            border-radius: 10px;
        }
        &::before{
            font-size: 15px;
            font-style: normal;
            color: #aaa;
        }
    }
    .ql-disabled{
        .ql-editor{
            cursor: default !important;
            pointer-events: none;
            -webkit-user-select: none;
            user-select: none;
            opacity: 0.6;
        }
    }
    .p-editor-toolbar{
        display: none;
    }
    .ql-formats{
        display: none;
    }
    .ql-toolbar{
        // position: absolute;
        // bottom: 1rem;
        // z-index: 999;
        // left: 1rem;
        // padding: 4px;
        // border-radius: 4px;
        background: #fff;
        cursor: default;
        pointer-events: none;
        -webkit-user-select: none;
        user-select: none;
        opacity: 0.6;
    }
    .p-editor-content{
        border-radius: 4px;
        border: 1px solid #D9D9D9 !important;
        background: #FFF;
    }

}
.paragraph-tag{
    p{
        color: #000;
        font-family: Lato;
        font-size: 16px;
        font-weight: 600;
        line-height: 120%;
        margin: 0px;
        position: relative;
        span{
            position: absolute;
            right: 2.5rem;
        }
    }
    h2{
        margin: 0px;
    }
}
.outline-button{
    background: #fff;
    border-radius: 6px;
    border: 1px solid #3E3F6C;
    height: 35px;
    margin: 0px 4px;
    span {
        font-weight: 500;
        font-size: 15px;
        color: #3E3F6C;
    }
    &:hover {
        background: $secondary !important;
        border: 1px solid $secondary !important;
        span{
            color: $white;
        }
    }
    &.add-tab{
        height: 40px;
    }
    .p-button-icon{
        font-weight: 700;
        font-size: 12px;
    }
}
.buttons-block{
    margin-top: 1rem;
    p{
        margin: 0px;
        font-size: 12px;
    }
    .add-category-btn{
        height: 35px;
        margin-left: 2px;
    }
}
.add-faq-button{
    width: 100%;
    border-radius: 8px;
    background: #3E3F6C;    
    height: 48px;
    border: none;
    margin-top: 0.8rem;
    span{
        font-size: 18px;
        font-weight: 500;
        line-height: 120%;
    }
    &:hover {
        background: $secondary !important;
    }
}
.delete-confirm-modal{
    width: 95%;
    max-width: 500px;
    .p-dialog-header{
        padding: 1rem 2rem;
    }
    .p-dialog-content{
        padding-top: 0.8rem;
        padding-bottom: 1.5rem;
    }
    .delete-confirm-content{
        p{
            margin: 0px 0px 2rem;
            font-size: 18px;
            color: #3E3F6C;
        }
        .outline-button{
            height: 40px;
            margin-right: 10px;
            &:hover{
                background: #3E3F6C !important;
                border: 1px solid #3E3F6C !important;
            }
        }
        .add-category-btn{
            background: $secondary;
            &:hover{
                opacity: 0.75;
            }

        }
    }
}
.custom-tabs{
    .p-tabview-nav{
        border: none;
        flex-wrap: wrap;
        li{
            margin-right: 12px;
            position: relative;
            margin-bottom: 12px;
            .p-tabview-nav-link{
                border: none;
                border-radius: 6px;
                border: 1px solid #EEE;
                padding: 10px 70px 10px 15px;
                color: #3E3F6C;
                font-family: Lato;
                font-size: 15px;
                font-style: italic;
                font-weight: 500;
                line-height: 120%;
                margin: 0 0 0px 0;
                &:focus{
                    box-shadow: none !important;
                }
                .edit-delete{
                    display: flex;
                    align-items: center;
                    gap: 2px;
                    position: absolute;
                    right: 10px;
                    .pi-pencil{
                        display: none;
                    }
                }
                &:hover{
                    .edit-delete{
                        display: flex;
                        .pi-pencil{
                            display: flex;
                        }
                    }
                }
                .tab-title{
                    line-height: 20px;
                    word-break: break-all;
                    white-space: break-spaces;
                }
                .p-inputtext{
                    height: 26px;
                    border: none;
                    padding: 0px 12px;
                    &:focus{
                        box-shadow: none;
                    }
                }
                .close-pi{
                    background: #3E3F6C;
                    color: #fff;
                }
            }
            &.p-tabview-ink-bar{
                display: none;
            }
            &.p-tabview-selected {
                .p-tabview-nav-link{
                    background: #3E3F6C;
                    border-color: #3E3F6C;
                    color: #fff;
                    .close-pi{
                        background: #fff;
                        color: #3E3F6C;
                    }
                }
            }
        }
    }
}
.tabs{
    .p-tabview-panels{
        padding: 0px;
    }
}
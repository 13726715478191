@import '../../assets/scss/variables';

.admin-login-section{
    width: 100%;
    height: 100vh;
    background: url('../../assets/images/admin-login-bg.jpg') no-repeat;
    background-size: cover;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    .login-wrapper{
        width: 100%;
        max-width: 520px;
        min-width: 520px;
        margin: 0 auto;
        border-radius: 0px 0px 10px 10px;
        background: $white;
        padding: 32px;
        box-shadow: 0px 0px 30px 20px rgba(0, 0, 0, 0.05);
        z-index: 1;
        .login-btn{
            background: $secondary;
            border: none;
            border-radius: 5px;
            height: 50px;
            span{
                font-weight: 500;
            }
            &:hover{
                background: $theme-gray;
            }
        }
        .p-inputtext{
            height: 45px;
            font-size: 14px;
            font-family: $font-base;
            border: 1px solid $blue-light;
            padding: 0.75rem 0.75rem 0.75rem 2.5rem;
        }
        .password-left{
            .p-password{
                .p-inputtext{
                    width: 100%;
                    padding-right: 0rem;
                    padding-left: 2.5rem;
                }
                .pi-eye{
                    right: auto;
                    left: 0.75rem;
                }
                .pi-eye-slash{
                    @extend .pi-eye;
                }
                .p-icon-field{
                    width: 100%;
                    .p-input-icon{
                        right: auto;
                        left: 0.75rem;
                    }
                }
            }
        }
        i{
            color: $theme-gray !important;
            font-size: 15px;
            margin-top: -6px;
            left: 0.75rem;
            &.pi-user{
                font-size: 14px;
            }
        }
    }
    .login-header{
        background: $grey-dark;
        border-radius: 10px 10px 0px 0px;
        padding: 25px 32px;
        .logo-img{
            img{
                width: 150px;
            }
        }
        h1{
            color: $white;
            margin: 0px;
            padding: 0px;
            font-weight: 500;
            font-size: 20px;
        }
    }
    p{
        text-align: center;
        font-weight: 400;
        font-size: 12px;
        color: $primary;
        margin-top: 65px;
        a{
            color: $primary;
            font-weight: 500;
            span{
                color: $secondary;
            }
        }
    }
}
.otp-wrapper{
    h6{
        color: #0E0020;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        line-height: 130%;
        margin: 0rem 0rem 1.8rem;
    }
}
.custom-otp-input {
    width: 36px;
    font-size: 26px;
    border: 0 none;
    appearance: none;
    text-align: center;
    transition: all 0.2s;
    background: transparent;
    border-bottom: 2px solid #AFAFAF;
    font-weight: 600;
    padding: 5px 0px;
    &:focus {
        outline: 0 none;
        border-bottom-color: #283149;
    }
    &::placeholder{
        font-size: 20px;
        font-weight: 500;
    }
}

@media (max-width:768px){
    .admin-login-section{
        .login-wrapper{
            padding: 20px;
            max-width: 92%;
            min-width: 92%;
        }
        .login-header{
            padding: 20px;
            max-width: 92%;
            margin: auto;
            .logo-img img{
                width: 115px;
            }
        }
    }
}